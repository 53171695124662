const actions = {
  DISABLED_ISSUER: 'issuer/DISABLED',
  CLOSED_ISSUER: 'issuer/CLOSED',
  LOADING_ISSUERS: 'issuer/LOADING_ISSUERS',
  LOADING_ISSUER: 'issuer/LOADING_ISSUER',
  LOADING_PURCHASE_HISTORY: 'issuer/LOADING_PURCHASE HISTORY',
  LOADING_CREDITS_ALL_ISSUERS: 'issuer/LOADING_CREDITS_ALL_ISSUERS',
  LOADING_CREDITS_ISSUER: 'issuer/LOADING_CREDITS_ISSUER',
  CLEAN_ALL_ISSUER: 'issuer/CLEAN_ALL',
  CLEAN_ISSUERS: 'issuer/CLEAN_ISSUERS',
  CLEAN_ISSUER: 'issuer/CLEAN_ISSUER',
  GET_ALL_ISSUER: 'issuer/GET_ALL',
  FETCH_ALL_ISSUER: 'issuer/FETCH_ALL',
  GET_ISSUER: 'issuer/GET',
  FETCH_ISSUER: 'issuer/FETCH',
  CREATE_ISSUER: 'issuer/CREATE',
  UPDATE_ISSUER: 'issuer/UPDATE',
  DELETE_ISSUER: 'issuer/DELETE',
  DELETE_ISSUER_CSD: 'issuer/DELETE_ISSUER_CSD',
  DELETE_ISSUER_FIEL: 'issuer/DELETE_ISSUER_FIEL',
  CREATE_CREDITS: 'issuer/CREATE_CREDITS',
  BUY_BILLING_STAMPS: 'issuer/BUY_BILLING_STAMPS',
  GET_PURCHASE_HISTORY: 'issuer/GET_PURCHASE_HISTORY',
  FETCH_PURCHASE_HISTORY: 'issuer/FETCH_PURCHASE_HISTORY',
  GET_CREDITS_ISSUER: 'issuer/GET_CREDITS_ISSUER',
  FETCH_CREDITS_ISSUER: 'issuer/FETCH_CREDITS_ISSUER',
  GET_CREDITS_ALL_ISSUERS: 'issuer/GET_CREDITS_ALL_ISSUERS',
  FETCH_CREDITS_ALL_ISSUERS: 'issuer/FETCH_CREDITS_ALL_ISSUERS',
  VERIFY_CERTS: 'issuer/VERIFY_CERTS',
};

export default actions;
