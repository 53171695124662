import localeAntd from 'antd/es/locale/en_US';

const messages = {
  // Generals
  'add.txt': 'Add',
  'edit.txt': 'To edit',
  // # TopBar
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit user config',
  'topBar.profileMenu.logout': 'Logout',
  // # Search
  'topBar.search.searchApp': 'Search within app',
  'topBar.search.pressEnter': 'Press enter to search',
  'topBar.search.noResults': 'No Results Found',
  'topBar.search.results': 'Pages Search Results',
  // # Agregar Factura
  'topBar.billing.title': 'Add invoice',
  'topBar.billing.add': 'Add new invoice',
  'topBar.billing.fromCanceled': 'Add invoice from canceled',
  'topBar.billing.paymentComplement': 'Payment complement',
  // # Locale
  'locale.key': 'en',
  'locale.es-MX': 'Spanish',
  'locale.en-US': 'English',
  // # Aplicación
  'app.name': ' ',
  'app.nickname': 'Stampi',
  // # Login
  'login.successfully.message': 'Logged In',
  'login.successfully.description': 'You have successfully logged in!',
  'login.resetPassword.confirm.message': 'Solicitud envíada',
  'login.resetPassword.confirm.description': 'La solicitud del cambio de contraseña se ha generado, revisa tu correo para poder actualizar la contraseña',
  'login.resetPassword.success.message': 'Contraseña modificada',
  'login.resetPassword.success.description': 'La contraseña se actualizó, ya puedes iniciar sesión',
  // # Nueva cuenta
  'register.successfully.message': 'Correct registration',
  'register.successfully.description': 'You have registered correctly!',
  // # Recuperar Contraseña
  'forgotPassword.title': 'Reset Password',
  'forgotPassword.emailMessage': 'Please input your e-mail address',
  'forgotPassword.emailPlaceholder': 'Email Address',
  'forgotPassword.button': 'Reset my password',
  'forgotPassword.back': 'Go to Sign in',
  'forgotPassword.password': 'Password',
  'forgotPassword.confirmPassword': 'Confirm Password',
  // # Inicio
  'index.title': 'Welcome to',
  'index.signIn': 'Sign in to your account',
  'index.emailMessage': 'Please input your e-mail address',
  'index.emailPlaceholder': 'usuario@email.com',
  'index.passwordMessage': 'Please input your password',
  'index.passwordPlaceholder': 'Password',
  'index.signInButton': 'Sign in',
  'index.forgotPassword': 'Forgot Password?',
  'index.dontHaveAccount': "Don't have an account?",
  'index.signUpButton': 'Sign up',
  // # Nuevo usuario
  'register.title': 'Create your account',
  'register.description':
    'And start spending more time on your projects and less time managing your infrastructure.',
  'register.nameMessage': 'Please input your full name',
  'register.namePlaceholder': 'Full Name',
  'register.emailMessage': 'Please input your e-mail address',
  'register.emailPlaceholder': 'Email Address',
  'register.passwordMessage': 'Please input your password',
  'register.passwordPlaceholder': 'Password',
  'register.signUp': 'Sign up',
  'register.terms01': 'By signing up, you agree to the',
  'register.terms02': 'Terms of Service',
  'register.terms03': 'and',
  'register.terms04': 'Privacy Policy',
  'register.alreadyHaveAccount': 'Already have an account?',
  'register.signIn': 'Sign in',
  // # Menú
  'menu.home': 'Home',
  'menu.category.dashboard': 'Dashboard',
  'menu.category.options': 'Options',
  'menu.category.billing': 'Billing',
  'menu.category.billing.draft': 'Draft',
  'menu.category.billing.stamped': 'Stamped',
  'menu.category.billing.canceled': 'Canceled',
  'menu.category.billing.pending': 'Cancel pending',
  'menu.category.billing.ppd': 'Payments on Partialities',
  'menu.category.bank': 'Bank',
  'menu.category.bank.accountStatements': 'Account statements',
  'menu.category.bank.myAccounts': 'My accounts',
  'menu.category.bank.associateInvoiceMovements': 'Associate movements',
  'menu.category.clients': 'Customers',
  'menu.category.clients.myClients': 'My clients',
  'menu.category.transmitter': 'Issuer',
  'menu.category.transmitter.myIssuers': 'My issuers',
  'menu.category.setting': 'Setting',
  'menu.category.setting.users': 'Users',
  'menu.category.setting.myTeam': 'My team',
  'menu.category.profile': 'User config',
  'menu.category.accounts.receivable': 'SAT',
  'menu.category.accounts.payable': 'Accounts payable',
  'menu.category.solicitude': 'Imports',
  'menu.category.preinvoice': 'prefactura',
  'menu.category.promotion': 'Promotions',
  'menu.category.promotion.myPromotions': 'My promotions',
  // # Acciones
  'billing.actions.add': 'Add invoice',
  'billing.actions.edit': 'Edit invoice',
  'billing.actions.transmitter': 'Issuer',
  'billing.actions.receptor': 'Receiver',
  'billing.actions.total': 'Total',
  'billing.actions.progress': 'Progress',
  'billing.actions.date': 'Issue date',
  'billing.actions.updatedAt': 'Ultimate Edition.',
  'billing.actions.actions': 'Actions',
  'billing.actions.pay': 'Pay',
  'billing.actions.cancel': 'Cancel',
  'billing.actions.submit': 'Submit',
  'billing.menu.copy': 'Copy UUID',
  'billing.menu.copy.message': 'The UUID was correctly copied',
  'billing.menu.edit': 'Edit',
  'billing.menu.view': 'See details',
  'billing.menu.upload': 'Upload documents',
  'billing.menu.download': 'Download',
  'billing.menu.downloadXML': 'Download XML',
  'billing.menu.downloadPDF': 'Download PDF',
  'billing.menu.downloadZIP': 'Download ZIP',
  'billing.menu.sendEmail': 'Send by e-mail',
  'billing.menu.sendEmisor': 'Send to emitter',
  'billing.menu.sendReceptor': 'Send to receiver.',
  'billing.menu.sendOtros': 'Send to others',
  'billing.menu.sendBilling': 'Send invoice',
  'billing.menu.cancel': 'Cancel',
  'billing.folio': 'Folio',
  'billing.folio.copy.message': 'The folio was copied correctly',
  'billing.invoice.relation': 'Create related invoice',
  'billing.menu.downloadAcuse.xml': 'Acuse cancellation (XML)',
  // # Helmet
  'helmet.lockscreen': 'Lockscreen',
  'helmet.page404': 'Page 404',
  'helmet.page500': 'Page 500',
  'helmet.login': 'Login',
  // # Páginas de error
  'error.message': 'Error',
  'pages.error.notFound': 'Page not found',
  'pages.error.serverError': 'Server Error',
  'pages.error.description': 'This page is deprecated, deleted, or does not exist at all',
  'pages.error.goBack': 'Go Back',
  // # Table
  'table.pagination.of': 'of',
  'table.pagination.items': 'items',
  // # Otros
  'backtop.title': 'Up',
  'empty.title': 'No data',
  // # Custom Components
  'custom.rfc': 'TaxID',
  'custom.rfc.name': 'Name',
  'custom.rfc.address': 'Address',
  'custom.rfc.phone': 'Phone',
  'custom.rfc.mobilePhone': 'Mobile',
  'custom.rfc.email': 'Email',
  'custom.rfc.paidOut': 'Paid out',
  'custom.rfc.charged': 'Charged',
  'custom.rfc.invoiced': 'Invoiced',
  'custom.rfc.viewDetails': 'View details',
  'custom.rfc.viewContact': 'See another contact',
  'custom.rfc.hideContacts': 'Hide contacts',
  'custom.rfc.openWhatsapp': 'Open with Whatsapp',
  'custom.rfc.call': 'Call',
  'custom.rfc.copyPhone': 'Copy number',
  'custom.rfc.copyPhone.message': 'The number was copied correctly',
  'custom.rfc.copyRFC.message': 'The TaxID was correctly copied',
  'custom.rfc.goMap': 'See map',
  'custom.rfc.copyAddress': 'Copy address',
  'custom.rfc.copyAddress.message': 'The address was copied correctly',
  // Ventanas de confirmación
  'modal.error.useModal': 'You must start the Hook Usemodal with { intl }',
  'modal.title': 'Title of the modal',
  'modal.content': 'Content of the modal',
  'modal.onText': 'Ok',
  'modal.cancelText': 'Cancel',
  'modal.delete.title': 'Are you sure to delete?',
  'modal.delete.clients.warning':
    'The action that is about to be done can not be undone. Only customers who do not have any move can be deleted.',
  'modal.delete.clients.error':
    'Unfortunately we have not been able to correctly eliminate the client',
  'modal.delete.issuer.warning':
    'The action that is about to be done can not be undone.Only emitters that have no movement can be eliminated.',
  'modal.delete.issuer.error': 'Unfortunately we have not been able to correctly eliminate the issuer',
  'modal.delete.okText': 'Delete',
  'modal.delete.cancelTxt': 'Close',
  'modal.billing.cancel.okText': 'Cancel',
  'modal.billing.cancel.title': 'Are you sure to cancel? ',
  'modal.billing.cancel.description': 'The action that is about to be done can not be undone.You can only cancel timbrate invoices',
  'modal.billing.cancel.error': 'Unfortunately we have not been able to cancel the invoice ',
  'modal.billing.cancel.success': 'The invoice was canceled ',
  'modal.billing.delete.description': 'The action that is about to be done can not be undone.You can only delete invoices in drafts',
  'modal.billing.fromCancel.title': 'Are you sure to generate a new invoice?',
  'modal.billing.fromCancel.description': 'The action that is about to be carried out will generate a new draft with the information of the invoice canceled based on the filler guide of digital tax vouchers (Annex 20 of the MRF)',
  'modal.billing.fromCancelText': 'generate',
  // Clientes
  'client.add.txtButton': 'Add client',
  'client.edit.txtButton': 'Edit client',
  'client.delete.txtButton': 'Delete client',
  'client.input.clientCode': 'Client code',
  'client.input.clientCode.placeholder': 'Capture the client code',
  'client.input.clientCode.error': 'The code of the client is incorrect',
  'client.input.logo': 'Logo',
  'client.input.profilePicture': 'Profile picture',
  'client.input.profilePicture.placeholder': 'Click to upload',
  'client.input.profilePicture.error': 'An error occurred when loading the image',
  'client.input.tradeName': 'Trade name',
  'client.input.tradeName.placeholder': 'Capture the trade name',
  'client.input.tradeName.error': 'The trade name is mandatory',
  'client.input.businessName': 'Business name',
  'client.input.businessName.placeholder': 'Capture the business name',
  'client.input.businessName.error': 'The business name is mandatory',
  'client.input.rfc': 'RFC',
  'client.input.rfc.placeholder': 'Capture the RFC',
  'client.input.rfc.error': 'The RFC is incorrect',
  'client.input.companyId': 'Company',
  'client.input.companyId.placeholder': 'Select the company',
  'client.input.companyId.error': 'You need to select a valid company',
  'client.input.postalCode': 'Postal Code',
  'client.input.postalCode.placeholder': 'Capture a zip code',
  'client.input.postalCode.error': 'The zip code is mandatory',
  'client.input.useCfdiId': 'Use of CFDi',
  'client.input.useCfdiId.placeholder': 'Capture one uso of the CFDi',
  'client.input.useCfdiId.error': 'The use of the CFDi is mandatory',
  'client.input.contact': 'Contact',
  'client.input.addContact': 'Add new contact',
  'client.input.deleteContact': 'Delete contact',
  'client.input.contact.fullName': 'Full name',
  'client.input.contact.fullName.placeholder': 'Capture a full name',
  'client.input.contact.fullName.error': 'The full name is mandatory',
  'client.input.contact.email': 'Email',
  'client.input.contact.email.placeholder': 'Capture an email',
  'client.input.contact.email.error': 'Email is required',
  'client.input.contact.address': 'Address',
  'client.input.contact.address.placeholder': 'Capture an address',
  'client.input.contact.address.error': 'The address is mandatory',
  'client.input.contact.lada': 'Lada',
  'client.input.contact.lada.placeholder': 'Capture a lada',
  'client.input.contact.lada.error': 'The lada is mandatory',
  'client.input.contact.officePhone': 'Office phone',
  'client.input.contact.officePhone.placeholder': 'Capture an office phone',
  'client.input.contact.officePhone.error': 'The office phone is mandatory',
  'client.input.contact.cellPhone': 'Cell phone',
  'client.input.contact.cellPhone.placeholder': 'Capture a cell phone',
  'client.input.contact.cellPhone.error': 'The cell phone is mandatory',
  'client.input.contact.linkedIn': 'linkedIn',
  'client.input.contact.linkedIn.error': 'The LinkedIn is mandatory',
  'client.input.contact.facebook': 'facebook',
  'client.input.contact.facebook.error': 'Facebook is mandatory',
  'client.input.contact.twitter': 'twitter',
  'client.input.contact.twitter.error': 'The Twitter is mandatory',
  'client.input.contact.instagram': 'instagram',
  'client.input.contact.instagram.error': 'Instagram is mandatory',
  'client.input.contact.url': 'url',
  'client.input.contact.url.placeholder': 'captureUnaUrl',
  'client.input.contact.url.error': 'The URL is mandatory',
  'client.input.placeID': 'PlaceID',
  // Emisores
  'issuer.add.txtButton': 'Add issuer',
  'issuer.edit.txtButton': 'Edit issuer',
  'issuer.delete.txtButton': 'Delete issuer',
  'issuer.buyBillingStamps.txtButton': 'Buy billing stamps',
  'issuer.buyBillingStamps.input.creditsNumber': 'Number of credits',
  'issuer.buyBillingStamps.input.creditsNumber.placeholder': 'Capture the number of credits',
  'issuer.buyBillingStamps.input.creditsNumber.error': 'The number of credits is incorrect',
  'issuer.purchaseHistory.table.createdAt': 'Created at',
  'issuer.purchaseHistory.table.credits': 'Credits',
  'issuer.purchaseHistory.table.endDate': 'Expiration date',
  'issuer.purchaseHistory.table.status': 'Status',
  'issuer.purchaseHistory.table.title': 'Purchase history',
  'issuer.input.taxRegimeId': 'Tax regime',
  'issuer.input.taxRegimeId.placeholder': 'Capture a tax regime',
  'issuer.input.taxRegimeId.error': 'Tax regime is mandatory',
  'issuer.input.keyFile': '.Key file',
  'issuer.input.keyFile.placeholder': 'Add the .key file',
  'issuer.input.keyFile.error': 'The .key file is mandatory',
  'issuer.input.password': '.Key password',
  'issuer.input.password.placeholder': 'Capture the .key password',
  'issuer.input.password.error': 'The password is mandatory',
  'issuer.input.certFile': 'File .cert',
  'issuer.input.certFile.placeholder': 'Add the .Cert file',
  'issuer.input.certFile.error': 'The .cert file is mandatory',
  'issuer.input.certificates': 'Certificates',
  'issuer.input.addCertificates': 'Renew certificate',
  'issuer.input.deleteCertificates': 'Delete certificate',
  'issuer.input.series': 'Series',
  'issuer.input.series.placeholder': 'Add one or more Series',
  'issuer.input.series.error': 'A series should be defined mini',
  'issuer.input.lastFolio': 'Last folio',
  'issuer.input.postalCode.placeholder': 'Define the last folio',
  'issuer.input.postalCode.error': 'The last folio is mandatory',
  'issuer.input.logo': 'Logo',
  'issuer.input.logo.placeholder': 'Add image',
  'issuer.input.logo.error': 'The image of the invoice is mandatory',
  'issuer.input.responsible': 'Responsible',
  'issuer.input.responsible.placeholder': 'Choose one or more responsible',
  'issuer.input.responsible.error': 'Must define minimum one responsible',
  // Billing
  'billing.group.name': 'Invoice',
  'billing.input.issuer': 'Issuer',
  'billing.input.issuer.placeholder': 'Select an issuer',
  'billing.input.issuer.error': 'The issuer is mandatory',
  'billing.input.client': 'Receiver',
  'billing.input.client.placeholder': 'Select a receiver',
  'billing.input.client.error': 'The receiver is mandatory',
  'billing.input.paymentForm': 'Form of payment',
  'billing.input.paymentForm.placeholder': 'Select a payment method',
  'billing.input.paymentForm.error': 'The form of payment is mandatory',
  'billing.input.paymentMethod': 'Payment method',
  'billing.input.paymentMethod.placeholder': 'Select a payment method',
  'billing.input.paymentMethod.error': 'The payment method is mandatory',
  'billing.input.invoiceType': 'Type of invoice',
  'billing.input.invoiceType.placeholder': 'Select an invoice type',
  'billing.input.invoiceType.error': 'The type of invoice is mandatory',
  'billing.input.serial': 'Serie',
  'billing.input.serial.placeholder': 'Select a series',
  'billing.input.serial.error': 'The series is mandatory',
  'billing.input.folio': 'Folio',
  'billing.input.folio.placeholder': 'Select a folio',
  'billing.input.folio.error': 'The folio is mandatory',
  'billing.input.currency': 'Currency',
  'billing.input.currency.placeholder': 'Select a currency',
  'billing.input.currency.error': 'The currency is mandatory',
  'billing.input.exchangeRate': 'Exchange rate',
  'billing.input.exchangeRate.placeholder': 'Select a exchange rate',
  'billing.input.exchangeRate.error': 'The exchange rate is mandatory',
  'billing.input.concepts': 'Concepts',
  'billing.input.concept': 'Concept',
  'billing.input.addConcept': 'Add another concept',
  'billing.input.deleteConcept': 'Remove concept',
  'billing.input.concept.productKey': 'Product or service key',
  'billing.input.concept.productKey.desc': 'Description product or service key',
  'billing.input.concept.productKey.placeholder': 'Select a product or service key',
  'billing.input.concept.productKey.error': 'The key or service key is mandatory',
  'billing.input.concept.unityKey': 'Unit key',
  'billing.input.concept.unityKey.desc': 'Description unit key',
  'billing.input.concept.unityKey.placeholder': 'Select a unit key',
  'billing.input.concept.unityKey.error': 'The unit key is mandatory',
  'billing.input.concept.quantity': 'Quantity',
  'billing.input.concept.quantity.placeholder': 'Capture an amount',
  'billing.input.concept.quantity.error': 'The amount is mandatory',
  'billing.input.concept.unity': 'Unit',
  'billing.input.concept.unity.placeholder': 'Select a unit',
  'billing.input.concept.unity.error': 'The unit is mandatory',
  'billing.input.concept.description': 'Description',
  'billing.input.concept.description.placeholder': 'Capture a description',
  'billing.input.concept.description.error': 'The description is mandatory',
  'billing.input.concept.unitValue': 'Unit value',
  'billing.input.concept.unitValue.placeholder': 'Capture a unitary value',
  'billing.input.concept.unitValue.error': 'The unit value is mandatory',
  'billing.input.concept.amount': 'Imported',
  'billing.input.concept.amount.placeholder': 'Capture an amount',
  'billing.input.concept.amount.error': 'The amount must be greater than or equal to 0',
  'billing.input.concept.discount': 'Discount',
  'billing.input.concept.discount.title': 'Discounts',
  'billing.input.concept.discount.type': 'Discount type',
  'billing.input.concept.discount.placeholder': 'Capture a discount',
  'billing.input.concept.discount.error': 'The discount is mandatory',
  'billing.open.catalog.prodserv': 'Open catalog of products and services',
  'billing.open.catalog.unidades': 'Open catalog of units',
  'billing.open.catalog.prodserv.search': 'Capture a word with minimum 4 characters and press the ENTER key',
  'billing.submit.error.without.concepts': 'No valid concepts were detected',
  'billing.submit.success': 'The invoice has been generated correctly',
  'billing.concept.success': 'The information is updated correctly',
  'billing.input.generalData.title': 'General data',
  'billing.input.impuesto.title': 'Taxes',
  'billing.input.impuesto.type': 'Tax type',
  'billing.input.impuesto.type.placeholder': 'Select the Tax Tax',
  'billing.input.impuesto.type.error': 'It is necessary to select a Tax type',
  'billing.input.impuesto.impuesto': 'Tax',
  'billing.input.impuesto.impuesto.placeholder': 'Select the tax',
  'billing.input.impuesto.impuesto.error': 'It is necessary to select a tax',
  'billing.input.impuesto.tasaOCuota': 'Rate or fee?',
  'billing.input.impuesto.tasaOCuota.placeholder': 'Select the rate or fee',
  'billing.input.impuesto.tasaOCuota.error': 'It is necessary to select a rate or fee',
  'billing.input.impuesto.valorTasaOcuota': 'Value rate or fee',
  'billing.input.impuesto.valorTasaOcuota.placeholder': 'Capture a value of the rate or fee',
  'billing.input.impuesto.valorTasaOcuota.error': 'It is necessary to capture a value of the rate or fee',
  'billing.input.impuesto.add': 'Add tax',
  'billing.input.impuesto.remove': 'Remove tax',
  'billing.input.impuesto.error': 'The tax to add is not valid',
  'billing.input.total': 'Total',
  'billing.input.subtotal': 'Subtotal',
  'billing.input.retenciones': 'Total taxes retained',
  'billing.input.traslados': 'Total transfer taxes',
  'billing.input.retenido': 'Detained',
  'billing.input.trasladado': 'Movement',
  'billing.input.concept.update': 'Update concept information',
  'billing.subtitle.receptor': 'This section is selected, the entity that will receive the invoice, RFC data and zip code are only informative.The use of the CFDI is automatically loaded based on the receiver\'s information, but it has the option to choose any use that creates convenient.',
  'billing.subtitle.emisor': 'In this section you can select any entity that you have registered for the emission of invoices, RFC data and zip code are only informative.The tax regime is automatically loaded based on the information of the issuer, but it has the option to choose any regime that creates convenient.',
  'billing.subtitle.factura': 'In this section, the general information for the timbing of the invoice is captured, the only data that are optional are the series and the folio.',
  'billing.subtitle.conceptos': 'This section adds the concepts to be invoiced.To detail more information, click on the symbol: ( > ).The predetermined unit is part.',
  'billing.subtitle.generales': 'This section defines the custom unit, the unit key and the fixed price discount or percentage.',
  'billing.subtitle.impuestos': 'In this section, the concept taxes are defined, the options will be enabled as you select the required options.It is important to click on the ( + ) button to add the tax.',
  'billing.helper.currency': 'In case the invoice is not in pesos here you can select the corresponding currency',
  'billing.helper.exchangeRate': 'The exchange rate is always in pesos, here you must capture the current value (in pesos) of the selected currency quote',
  'billing.helper.footer.importe': 'The sum of the unit value for the amount applied to the concepts',
  'billing.helper.footer.descuento': 'The sum of the discounts applied to the concepts',
  'billing.helper.footer.subtotal': 'The result of subtracting discounts to the amount (in case there are discounts)',
  'billing.helper.footer.total': 'The sum of the subtotal plus all the taxes retained',
  'billing.concept.error': 'It is necessary to select a SAT unit key',
  'billing.input.emisor.addNew': 'Add new issuer',
  'billing.input.receptor.addNew': 'Add new receiver.',
  'billing.input.emisor.edit': 'Edit emitter',
  'billing.input.receptor.edit': 'Edit receiver',
  'billing.input.fechaExpedicion': 'Expedition date',
  'billing.input.fechaExpedicion.error': 'The date is mandatory',
  'billing.input.save.timbrar': 'Save and timbrar.',
  'billing.draft.submit.create.success': 'An invoice was added to drafts',
  'billing.draft.submit.update.success': 'The draft invoice was updated',
  'billing.draft.submit.stamp.success': 'The timbing of the invoice is generated',
  'billing.draft.submit.stamp.initial': 'The rumped process started',
  'billing.actions.view': 'Invoice detail',
  'billing.timbrado.title': 'Tax stamps',
  'billing.timbrado.subtitle': 'This document is a digital representation of a CFDI',
  'billing.timbrado.noCertificado': 'Series of the issuer certificate',
  'billing.timbrado.uuid': 'Folio folio',
  'billing.timbrado.noCertificadoSAT': 'Serial No. SAT certificate',
  'billing.timbrado.fechaTimbrado': 'Date and time of certification',
  'billing.timbrado.version': 'Version',
  'billing.timbrado.selloCFD': 'Digital CFDI seal',
  'billing.timbrado.selloSAT': 'SAT stamp',
  'billing.timbrado.cadenaOriginal': 'Original SAT digital certificate complement chain',
  'billing.draft': 'Draft',
  'billing.actions.close': 'Close',
  'billing.input.concept.withTax': 'With tax',
  'billing.input.concept.withTax.error': 'The captured value is not valid',
  'billing.input.price.withTax': 'Prices with tax',
  'billing.input.price.withCfdiRelacionado': 'Relate invoices',
  'billing.contacts.noFound': 'No contacts were found to send the mail',
  'billing.contacts.receiver': 'Receivers.',
  'billing.contacts.receiver.error': 'You need to choose minimum of a receiver',
  'billing.sendMail.success.title': 'Emails sent',
  'billing.sendMail.success.description': 'The emails were sent correctly',
  'billing.input.tipoRelacion': 'Type of relationship CFDI',
  'billing.input.tipoRelacion.placeholder': 'Select the CFDI ratio type',
  'billing.input.cfdiRelacionados': 'UUID (folios) related',
  'billing.input.cfdiRelacionados.placeholder': 'Capture the UUIDs (folios) related',
  'billing.input.cfdiRelacionados.verificar': 'Display related invoices',
  'billing.input.cfdiRelacionados.error': 'Uno o más de los UUID capturados no es válido',
  'billing.cancel': 'Canceled',
  'billing.stamp': 'Stamped',
  'billing.cancelacion.title': 'Acknowledgment of cancellation',
  'billing.cancelacion.subtitle': 'This document is canceled before the SAT',
  'billing.stamp.waiting': 'Estamos realizando el proceso de timbrado esto puedo demorar unos segundos',
  'billing.emptyInbox': 'Congratulations, your inbox is empty',
  // Mi equipo
  'myTeam.tabs.info': 'Info',
  'myTeam.tabs.api': 'API',
  // Team
  'team.edit.txtButton': 'Edit Team',
  'team.add.txtButton': 'Create Team',
  'team.inviteUsers': 'Invite users',
  'team.invite': 'Invite',
  'team.user.name': 'Name',
  // Configuración
  'config.menu.information': 'Information',
  'config.menu.team': 'Team',
  'config.menu.members': 'Members',
  'config.menu.api': 'API',
  'config.menu.webhook': 'Webhooks',
  'config.menu.webhook.logs': 'Webhooks Logs',
  'config.menu.billingPlans': 'Billing & plans',
  'config.menu.security': 'Safety',
  // API
  'api.input.add.txtButton': 'Add API.',
  'api.input.edit.txtButton': 'Edit API',
  'api.input.apiKey': 'API Key',
  'api.input.apiId': 'API ID',
  'api.input.createdAt': 'Created the',
  'api.input.updatedAt': 'Updated the',
  'api.input.revoke': 'Revocar API Key',
  'api.input.show': 'Watch',
  'api.input.hide': 'Hide',
  // Otros
  'generic.commingSoon': 'Coming soon, site under construction',
  'generic.actions': 'Actions',
  'generic.field': 'Field',
  'generic.add': 'Add new field',
  'generic.delete': 'Delete field',
  'generic.message.error': 'Incorrect value',
  // Catálogo Productos Sevicios SAT
  'prodserv.title': 'Catalog of keys of products and SAT services',
  'prodserv.title.keyword': 'Search by keyword',
  'prodserv.title.category': 'Search by categories',
  'prodserv.type': 'Type',
  'prodserv.products': 'Products',
  'prodserv.services': 'Services',
  'prodserv.type.placeholder': 'Select the type',
  'prodserv.division': 'Division',
  'prodserv.division.placeholder': 'Select the division',
  'prodserv.group': 'Group',
  'prodserv.group.placeholder': 'Select the group',
  'prodserv.class': 'Class',
  'prodserv.class.placeholder': 'Select the class',
  'prodserv.keyword': 'Keyword',
  'prodserv.keyword.placeholder': 'Search by keyword or product code',
  'prodserv.keyword.message': 'The word to search must be at least 4 characters',
  'prodserv.title.results': 'Results',
  // Catálogo Unidades SAT
  'unidades.title': 'Catalog of SAT units keys',
  'unidades.title.code': 'Search by code',
  'unidades.subtype': 'Subtype',
  'unidades.subtype.placeholder': 'Select the subtype.',
  'unidades.keyword.placeholder': 'Search by keyword',
  'unidades.keyword.message': 'The word to look for must have a minimum 4 characters',
  'unidades.code': 'Unit code',
  'unidades.code.placeholder': 'Search by unit code',
  'unidades.code.message': 'The code to search must have a minimum 2 characters',
  // Configuración de usuario
  'user.menu.profile': 'Profile',
  'user.menu.email': 'Change of email',
  'user.menu.company': 'Companies',
  'user.menu.mycompanies': 'My companies',
  'user.email.newMail': 'New email',
  'user.email.solicitud': 'Generate request to change the mail',
  'user.email.info': 'Information on the application',
  'user.email.description': 'When generating a new application for the change of email, an email will be sent to the current mail and the new one;In the latter you must click on the link to be able to make the change',
  'user.email.success.message': 'Request sent',
  'user.email.success.description': 'The request was sent to generate the password change, check your mail to complete the process',
  'invitation.email.message': 'Information about the mail to register ',
  'invitation.email.description': 'Your account will be associated with the following email, later you can change the mail account',
  'user.menu.billing': 'Billing and plans',
  // Companías
  'company.name': 'Name',
  'company.name.error': 'You need to capture a valid name',
  'company.name.placeholder': 'Capture the name of the company',
  'company.users': 'Total users',
  'company.add.txtButton': 'Add company',
  'company.edit.txtButton': 'Edit company',
  'company.delete.txtButton': 'Delete company',
  'company.invitation.txtButton': 'Invite users',
  'modal.delete.company.warning': 'The action that is about to be done can not be undone.Only companies that have no movement can be deleted.',
  'company.invitation.mails.txt': 'Invitations',
  'company.invitation.mails.add': 'Add new email',
  'company.invitation.mails.delete': 'Remove',
  'company.mail': 'Email',
  'company.mail.error': 'You need to capture an email',
  'company.mail.placeholder': 'Capture the email',
  'company.info.message': 'Information about these companies',
  'company.info.description': 'The companies shown below are all that you have access to create customers, issuers, invoices, etc.',
  'company.exit': 'Exit of the company',
  'company.creator': 'Creator',
  'company.user': 'User',
  'company.exit.title': 'Exit of the company',
  'company.exit.okText': 'Exit',
  'company.exit.cancelTxt': 'Cancel',
  'company.exit.warning': 'The action that is about to be done can not be undone.',
  'company.select': 'Select company',
  // Webhooks
  'webhook.input.name': 'Nombre',
  'webhook.input.url': 'Endpoint',
  'webhook.input.status': 'Estatus',
  'webhook.add.txtButton': 'Add webhook',
  'webhook.edit.txtButton': 'Edit webhook',
  'webhook.input.name.placeholder': 'Capture the name of the webhook',
  'webhook.input.name.error': 'It is necessary to capture a valid name',
  'webhook.input.url.placeholder': 'Capture the url of the webhook',
  'webhook.input.url.error': 'It is necessary to capture a valid URL',
  'webhook.delete.warning': 'The action that is about to be done can not be undone.Only webhooks that do not have any move can be removed.',
  'webhook.delete.txtButton': 'Delete webhook',
  'webhook.active.txtButton': 'Activate webhook',
  'webhook.inactive.txtButton': 'To disable webhook',
  'logs.description': 'Status Code',
  'logs.type': 'Content-Type',
  'logs.response': 'Response',
  'logs.status': 'Status',
  'logs.usuario': 'User',
  'logs.action': 'Action',
  'logs.tooltip.statusCode': 'The maximum of 10 attempts was exceeded, we have disabled the webhook, please check that it is working and manually enable it to be able to deliver messages',
  'logs.tooltip.status': 'There was a problem with your webhook, we will make several attempts until you can deliver the message',
  'logs.user.externo': 'External',
  // Billing error metadata
  'metadata.error.message': 'Metadata error',
  'metadata.error.description': 'The metadata format is incorrect, please check the structure',
  'billing.merge.txt': 'Merge billing',
  'billing.merge.uploadExcel': 'Upload Excel',
  'billing.group': 'Group',
  // Opciones billing
  'billing.menu.split': 'Split by concepts',
  'modal.billing.join.title': 'Are you sure to unite the concepts of invoices?',
  'modal.billing.join.okText': 'Join',
  'modal.billing.join.description': 'The action that is about to be performed can not be undone, the only possible alternative is to separate the invoice by concepts.Only invoices of the same group can be joined.',
  'modal.billing.split.title': 'Are you sure to separate the concepts of invoices?',
  'modal.billing.split.okText': 'Split',
  'modal.billing.split.description': 'The action that is about to be done can not be undone.Only invoices can be separated with more than one concept.',
  // Pagos
  'billing.invoice.ppd.nopago': 'No. Pay',
  'billing.invoice.ppd.paydate': 'Pay day',
  'billing.invoice.ppd.formapago': 'Form payment',
  'billing.invoice.ppd.currency': 'Currency',
  'billing.invoice.ppd.tipocambio': 'Exchange rate',
  'billing.invoice.ppd.saldoant': 'Previous debt',
  'billing.invoice.ppd.pago': 'Payment',
  'billing.invoice.ppd.deuda': 'Remaining',
  'billing.input.pagos.fechaPago': 'Pay day',
  'billing.input.pagos.fechaPago.error': 'It is necessary to capture the date of payment',
  'billing.input.pagos.monto': 'Amount',
  'billing.input.pagos.monto.error': 'Capture the payment amount',
  'billing.input.pagos.numOperacion': 'No. Operation',
  'billing.input.pagos.rfcEmisorCtaOrd': 'RFC emisor (CTA. Words.)',
  'billing.input.pagos.nomBancoOrdExt': 'banco (ordExt.)',
  'billing.input.pagos.ctaOrdenante': 'CTAservant',
  'billing.input.pagos.rfcEmisorCtaBen': 'RFC Emitor (CTA Ben.)',
  'billing.input.pagos.ctaBeneficiario': 'CTAbeneficiary',
  'billing.input.pagos.tipoCadPago': 'Type of payment chain',
  'billing.input.pagos.certPago': 'Payment certificate',
  'billing.input.pagos.certPago.error': 'You must capture the certificate that corresponds to the payment',
  'billing.input.pagos.cadPago': 'Chain of payment',
  'billing.input.pagos.cadPago.error': 'You must capture the original chain of proof that corresponds to payment',
  'billing.input.pagos.selloPago': 'Payment stamp',
  'billing.input.pagos.selloPago.error': 'You must capture the digital seal that corresponds to the payment',
  'billing.input.pagos.idDocumento': 'UUID',
  'billing.input.pagos.numParcialidad': 'I do not pay',
  'billing.input.pagos.impSaldoAnt': 'Previous balance',
  'billing.input.pagos.impPagado': 'Amount paid',
  'billing.input.pagos.impPagado.error': 'You must capture a valid value',
  'billing.input.pagos.impSaldoInsoluto': 'Unsolved balance',
  'billing.input.pagos.add': 'Add payment complement',
  'billing.input.pagos.edit': 'Edit payment complement',
  'billing.input.pagos.delete': 'Remove payment add-on',
  'billing.input.pagos.name': 'General',
  'billing.input.pagos.name.tooltip': 'General information for filling the payment complement',
  'billing.input.pagos.complemento': 'Complement',
  'billing.input.pagos.complemento.tooltip': 'Information necessary for filling the payment complement',
  'billing.invoice.pago': 'Generate payment complement',
  'billing.invoice.validation.message': 'Incorrect amounts!',
  'billing.invoice.validation.description': 'Verify that the amount paid is not greater than the previous balance',
  'billing.input.pagos.detail': 'Detail of the payment complement',
  'billing.generate.pdf': 'Wait a few seconds while we generate the PDF document',
  'billing.generate.zip': 'Wait a few seconds while we generate the ZIP document',
  'billing.pagos.stamp.description': 'Letterhead',
  'billing.pagos.stamp.pdf': 'Download pdf',
  // Bank Accounts
  'menu.category.bank.accounts': 'Bank accounts',
  'bank.accounts.add': 'Manage bank accounts',
  'bank.form.nocuenta': 'Account number',
  'bank.form.nocuenta.placeholder': 'Capture the account number',
  'bank.form.nocuenta.error': 'You must capture a valid account number',
  'bank.form.bank': 'Bank',
  'bank.form.bank.placeholder': 'Select a bank',
  'bank.form.bank.error': 'You must select the bank associated with your account',
  'bank.form.status': 'Status',
  'bank.form.status.placeholder': 'Define the status',
  'bank.form.status.error': 'The status is mandatory',
  'bank.add.txt': 'Add bank account',
  'bank.edit.txt': 'Edit bank account',
  'bank.delete.txt': 'Delete bank account',
  'metadata.title': 'Metadata',
  'metadata.input.frecuency': 'Frequency',
  'metadata.input.frecuency.placeholder': 'Select the frequency',
  'metadata.input.periodDate': 'Start date of the period',
  'metadata.input.periodDate.placeholder': 'Select the start date of the period',
  'metadata.input.dispersionDate': 'Date of dispersion',
  'metadata.input.dispersionDate.placeholder': 'Select the Dishes Date',
  'metadata.error': 'You need to correct this structure to be able to apply the elections',
  'attachments.title': 'Attachments',
  'search.receiver.placeholder': 'Search by code, taxID, social reason or trade name',
  'search.issuer.placeholder': 'Search by taxID, social reason or trade name',
  'modal.delete.bankaccounts': 'The action that is about to be done can not be undone.Only bank accounts that have no movement can be deleted.',
  'billing.stamp.cancel.waiting': 'We are performing the process to cancel the invoice this I can take a few seconds',
  'message.delete.waiting': 'We are doing the process to eliminate this can take a few seconds',
  'pagos.delete.txt': 'Remove payment add-on',
  'pagos.modal.delete.txt': 'The action that is about to be done can not be undone.Only payments of payments that have not been timbred can be removed.',
  'pagos.cancel.txt': 'Cancel payment add-on',
  'invoice.issuer.filter.placeholder': 'Choose or clean to see all',
  'invoice.download.all.xml': 'Download all XML',
  'invoice.download.all.zip': 'Download all zip (pdf and xml)',
  'invoice.clone.waiting': 'We are generating the eraser this can take a few seconds',
  'message.sendmail.waiting': 'We are sending the emails this can take a few seconds',
  'error.mail.message': 'Non-shipped emails',
  'success.mail.message': 'Post emails',
  'xml.mail.message': 'XML',
  'pdf.mail.message': 'PDF',
  'zip.mail.message': 'ZIP',
  'error.random.message': 'Without errors',
  'filter.startDate.title': 'Start date',
  'filter.endDate.title': 'End date',
  'filter.date.error.title': 'Incorrect date',
  'filter.startDate.error.title': 'The initial date can not be greater than the final date',
  'filter.endDate.error.title': 'The final date can not be less than the initial date',
  'zip.message.error': 'No information found to generate the ZIP document',
  save: 'Save',
  cancel: 'Cancel',
  'profile.name.text': 'Nombre completo',
  'profile.name.placeholder': 'Capture su nombre completo',
  'profile.name.error': 'Debe capturar un nombre',
  'profile.bio.text': 'Biography',
  'profile.bio.placeholder': 'Tell us a bit about yourself',
  'profile.email.text': 'Email',
  'profile.timezone.text': 'Time zone',
  'profile.timezone.placeholder': 'Capture your time zone',
  'profile.timezone.error': 'You must choose a time zone',
  'profile.picture.text': 'Profile picture',
  'profile.picture.upload': 'Upload profile photo',
  'profile.picture.delete': 'Remove profile photo',
  'profile.dateOfBirth.text': 'Birth',
  'profile.address.text': 'Address',
  'profile.address.placeholder': 'Capture an address',
  'profile.address.error': 'You must capture an address',
  'profile.cp.text': 'Postal Code',
  'profile.cp.placeholder': 'Capture your zip code',
  'profile.cp.error': 'You must capture a zip code',
  'profile.country.text': 'Country',
  'profile.country.placeholder': 'Select a country',
  'profile.country.error': 'You must choose a country',
  'profile.currency.text': 'Payment badge',
  'profile.currency.placeholder': 'Choose the payment currency',
  'profile.currency.error': 'You must select a payment currency',
  'profile.newpassword.text': 'Current password',
  'profile.newpassword.placeholder': 'New Password',
  'profile.newpassword.error.message': 'Passwords do not coincide',
  'profile.newpassword.error.description': 'The information could not be updated, passwords do not coincide',
  'menu.admin.text': 'Administrator',
  'menu.admin.product.text': 'Products',
  'product.name.text': 'Name',
  'product.name.placeholder': 'Capture the name of the product',
  'product.name.error': 'You must capture the name of the product',
  'product.price.text': 'Price',
  'product.price.placeholder': 'Capture the price of the product',
  'product.price.error': 'You must capture the price of the product',
  'product.quantity.text': 'Quantity',
  'product.quantity.placeholder': 'Capture the quantity of the product',
  'product.quantity.error': 'You must capture the amount of the product',
  'product.description.text': 'Description',
  'product.description.placeholder': 'Capture the product description',
  'product.description.error': 'You must capture the description of the product',
  'product.features.text': 'Features',
  'product.feature.text': 'Feature',
  'product.feature.placeholder': 'Capture a feature of the product',
  'product.feature.error': 'You must capture a product feature',
  'product.isfree.text': 'It\'s free',
  'product.status.text': 'Status',
  'product.createdAt.text': 'Creation',
  'product.button.add.text': 'Add product',
  'product.button.update.text': 'Edit product',
  'product.button.feature.text': 'New feature',
  'product.button.feature-delete.text': 'Remove feature',
  'product.active.text': 'Enable product',
  'product.inactive.text': 'Disable product',
  'product.principal.text': 'Mark product as the main',
  'product.principal': 'Principal',
  'message.principal.waiting': 'We are performing the process to assign the main product this can take a few seconds',
  'message.status.waiting': 'We are performing the process to change the status this can take a few seconds',
  'menu.buy-stamps.text': 'Buy stamps',
  'menu.buy-stamps.title': 'Choose the package you want to buy',
  'menu.user.payment-methods': 'Payment methods',
  'card.name': 'Name that appears on the card',
  'card.country': 'Country',
  'card.phone': 'Phone number',
  'card.card': 'Card Information',
  'card.card.title': 'Add a new card',
  'paymentmethod.last4.text': 'Ends in',
  'paymentmethod.type.text': 'Type',
  'paymentmethod.expire.text': 'Expire',
  'paymentmethod.delete.btn.text': 'Delete card',
  'paymentmethod.principal.btn.text': 'Mark as main',
  'message.paymentmethod.principal.waiting': 'We are performing the process to assign the card as the main this can take a few seconds',
  'modal.delete.paymentmethod.warning': 'The action that is about to be done can not be undone.',
  'step.one.text': 'Choose payment method',
  'step.two.text': 'Completed!',
  'step.buy.next.text': 'Next',
  'step.buy.previous.text': 'Previous',
  'step.process.previous.text': 'Processing payment',
  'step.buy.done.text': 'Pay',
  'step.buy.cancel.text': 'Cancel',
  'step.buy.reload.text': 'Restart',
  'step.buy.folios.text': 'facturas',
  'card.buy.text': 'Card for payment',
  'card.buy.placeholder': 'Choose the card for your payment',
  'card.buy.error': 'You must capture the card with which you will make the payment',
  'buy.stamp.details': 'Details',
  'buy.stamp.price': 'Price',
  'buy.stamp.quantity': 'Quantity',
  'buy.done.success': 'Payment made correctly',
  'paymentmethid.select.title': 'Choose the payment method',
  'invoice.status.filter.placeholder': 'Payment status',
  'history.title.text': 'History of stamps',
  'history.availableCredits': 'Available',
  'history.totalCredits': 'Total purchased',
  'history.usedCredits': 'Used total',
  'history.expiredCredits': 'Total expired',
  'menu.issuer.details': 'Details of the emitter',
  'menu.issuer.banks': 'Banks',
  'menu.issuer.my-buys': 'My shopping',
  'topBar.buy.stamps': 'Buy stamps',
  'issuer.buy.text': 'Issuer',
  'issuer.buy.placeholder': 'Choose the issuer that the rings will receive',
  'issuer.buy.error': 'You must choose the issuer that you will receive the rings',
  'billing.cancel.pending': 'Requested',
  'issuer.input.firstFolio': 'Initial folio',
  'issuer.input.firstFolio.placeholder': 'Capture the folio for the first invoice',
  'client.input.automaticFolio': 'Automatic folio.',
  'nps.title': 'Do you like Stampi? ',
  'nps.comments': 'Comments...',
  'nps.send': 'Send comments',
  'nps.close': 'Close',
  'issuer.input.lastFolio.placeholder': 'Capture the last assigned folio',
  'issuer.title.update.success': 'Updated Emitter!',
  'issuer.description.update.success': 'The update was done correctly ',
  'invoice.batch.block.tooltip': 'To unlock the folio check the emitter configuration',
  'invoice.doc.need': 'No lack of the fields needed to attach the documentss',
  'invoice.pagos.ctas': 'Accounts and banks',
  'invoice.pagos.ctas.desc': 'Optional nformation to track payment',
  'more.options': 'More options',
  'modal.billing.cancel.pay.okText': 'Cancel payment add-on',
  'invoice.download.ppd.report': 'Download report',
  refresh: 'Refresh',
  'motive.cancel.title': 'Cancel invoice',
  'motive.cancel.pago.title': 'Cancel payment add-on',
  'motive.cancel.description': 'What is the reason for cancellation?',
  'motive.cancel.placeholder': 'Select a cancellation motive',
  'motive.cancel.uuid.relation': 'For the selected reason, you need to associate the UUID of the new invoice',
  'motive.cancel.uuid.relation.placeholder': 'Capture the UUID.',
  'motive.cancel.when': 'Cases in which the selected motif applies',
  'client.input.Street': 'Street',
  'client.input.Street.error': 'You must capture the street',
  'client.input.Street.placeholder': 'Capture the street',
  'client.input.ExteriorNumber': 'Exterior number',
  'client.input.ExteriorNumber.error': 'You must capture the external number',
  'client.input.ExteriorNumber.placeholder': 'Capture the EXT number.',
  'client.input.InteriorNumber': 'Interior number',
  'client.input.InteriorNumber.error': 'You must capture the interior number',
  'client.input.InteriorNumber.placeholder': 'Int number.',
  'client.input.Neighborhood': 'Neighborhood',
  'client.input.Neighborhood.error': 'You must choose the colony',
  'client.input.Neighborhood.placeholder': 'Select the colony',
  'client.input.Locality': 'Locality',
  'client.input.Locality.error': 'You must choose a city',
  'client.input.Locality.placeholder': 'Select the city',
  'client.input.Municipality': 'Municipality',
  'client.input.Municipality.error': 'You must choose a municipality',
  'client.input.Municipality.placeholder': 'Select the municipality',
  'client.input.State': 'State',
  'client.input.State.error': 'You must choose a state',
  'client.input.State.placeholder': 'Select a state',
  'client.input.Country': 'Country',
  'client.input.Country.error': 'You must choose a country',
  'client.input.Country.placeholder': 'Select a country',
  'client.input.outsider': 'Foreign client',
  'client.input.taxResidence': 'Tax residence',
  'client.input.taxResidence.error': 'If the client is a foreigner, he must capture the tax residence',
  'client.input.taxResidence.placeholder': 'Capture the tax residence',
  'client.input.taxRegistrationNumber': 'Registration number',
  'client.input.taxRegistrationNumber.error': 'If the client is a foreigner, he must capture his registration number',
  'client.input.taxRegistrationNumber.placeholder': 'Capture the registration number',
  'issuer.input.lastFolioPago': 'Last folio payment',
  'issuer.input.lastFolioPago.placeholder': 'Last folio payment',
  'client.input.automaticFolioPago': 'Folio payment aut.',
  'client.input.automaticFolioPago.placeholder': 'Folio payment aut.',
  'client.input.taxResidence.tooltip': 'Conditional attribute to express the password of the country when it comes to foreigner, for example: USA',
  'client.input.taxRegistrationNumber.tooltip': 'Tax identity number in your country.Mandatory when it comes to foreigner',
  'billing.invoice.aplicar.pago': 'Apply payment',
  'billing.payment.add.title': 'Add payment',
  'billing.payment.edit.title': 'Payment',
  'billing.payment.waiting': 'We are applying the payment this I can take a few seconds',
  'billing.input.condiciones-pago': 'Payment condition',
  'billing.input.condiciones-pago.placeholder': 'Capture the condition of payment',
  'billing.input.exportaciones': 'Export',
  'billing.input.exportaciones.placeholder': 'Select the export type',
  'billing.input.exportaciones.error': 'The export type must be defined',
  'billing.input.objeto-imp': 'Tax object',
  'billing.input.objeto-imp.placeholder': 'Select the tax object',
  'billing.input.objeto-imp.error': 'The purpose of the tax must be defined',
  'invoice.download.ppd.topay': 'Apply related payment',
  'invoice.ppd.ppd.topay.title': 'Who will you apply the payment?',
  'billing.actions.payto': 'Payment to apply',
  stamp: 'Stamp',
  'menu.category.billing.complements': 'Payments',
  payment: 'Payment',
  'version.sat': 'SAT versions',
  'version.sat.cfdi': 'CFDI',
  'version.sat.pagos': 'Supplement of payments',
  'version.sat.cancelacion': 'Cancellation',
  'version.sat.error': 'The version is obligatory, you must select a',
  'generic.loading': 'Wait a minute...',
  'magic.login.error': 'An error occurred at the log',
  'client.input.blockBatchWebhook': 'Block Webhook folio',
  'client.input.blockRemoteUpdate.text': 'Blocked remote update',
  'client.input.blockBatchWebhook.description': 'This option prevents the bill from changing when keeping and keeping it even if the folio is automatic',
  'client.input.blockRemoteUpdate': 'Block remote update',
  'client.input.blockRemoteUpdate.description': 'This option prevents customer information from upset (using the webhooks)',
  'client.upload.cif': 'Update customer information from a PDF (CIF)',
  'client.upload.cif.placeholder': 'Attach document',
  'client.upload.cifvalues.title': 'Update customer from CIF',
  'client.upload.cifvalues.addtitle': 'Add customer from CIF',
  'client.upload.cifvalues.placeholder': 'Choose the information you want to update',
  'client.input.updateRFC': 'Update the RFC',
  'client.input.rfc.update.warning': 'The CIF RFC does not match the client\'s RFC',
  'client.input.updateBusinessName': 'Update the corporate name',
  'client.input.updatedTradeName': 'Update the commercial name',
  'client.input.updateAddress': 'Update address',
  'client.input.updatePostalCode': 'Update postal code',
  'client.input.updateRegime': 'Update tax regime',
  'client.add.txtButton.cif': 'Add client from CIF',
  'additional.value.title': 'Additional data',
  'additional.value.text': 'Capture a value',
  'additional.warning.description': 'Do not forget to save the invoice to correctly make the modification of additional data',
  'additional.key.period.description': 'Period',
  'additional.key.commision.description': 'Commission',
  'additional.key.costoSocial.description': 'Social cost',
  'additional.key.periodType.description': 'Type of period',
  'additional.key.periodTypeValue.description': 'Type of period type',
  'additional.alert.warning.message': 'Additional Data',
  'additional.alert.warning.description': 'In order to save or stamp the invoice, it is necessary to fill the information on additional data',
  'additional.key.observations.description': 'Observations',
  'billing.timbrado.sentBy': 'Sent by',
  'billing.timbrado.stampBy': 'Stamped by',
  'billing.timbrado.cancelBy': 'Canceled by',
  'topBar.status.active': 'Active',
  'topBar.status.inactive': 'Inactive',
  'draft.visible.to.me': 'Draft only visible to me',
  'draft.visible.to.me.description': 'When activating this option, the draft will only be visible to you',
  'modal.billing.join.joinConcepts': 'Join the concepts of the invoices (you can only join the concepts that have the same SAT code, description and unit value, when selecting this option it is not possible to add the period at the end of the description of each concept)',
  'modal.billing.join.addPeriod': 'Add name of the period at the end of the description of each concept (it is only possible if it is in the metadata, when selecting this option it is not possible to join concepts)',
  'banxico.error.title': 'Error obtaining the exchange rate',
  'billing.global.invoice.title': 'Global invoice',
  'billing.input.periodicidad': 'Periodicity',
  'billing.input.periodicidad.placeholder': 'Select the periodicity',
  'billing.input.periodicidad.error': 'You must select a periodicity',
  'billing.input.meses': 'Months',
  'billing.input.meses.placeholder': 'Select a month',
  'billing.input.meses.error': 'You must select one month',
  'billing.input.anio': 'Year',
  'billing.input.anio.error': 'You must capture a year',
  'billing.global.invoice.subtitle': 'Global Invoice Information',
  'billing.global.invoice.add': 'Add global invoice',
  'billing.global.invoice.delete': 'Eliminate global invoice',
  'billing.menu.cancel.status': 'Consult cancellation status',
  'billing.menu.cancel.description': 'Please click on the button to consult the current status of the invoice.Checkbox will only be disabled if information about the cancellation status of the invoice is not obtained.It should also be taken into account that the SAT may take up to 72 hours to update the cancellation status of the invoice.',
  'billing.menu.cancel.status.clean': 'Remove the cancellation of the invoice (it cannot be undone)',
  'billing.menu.cancel.button': 'Verify status',
  'billing.menu.cancel.revert.button': 'Revert cancellation',
  'billing.menu.cancel.forced': 'Force cancellation reversion',
  'pdf.language': 'PDF language',
  'pdf.language.error': 'You must select a language',
  'pdf.language.placeholder': 'Select the language',
  'billing.invoice.clone': 'Clone invoice',
  'modal.billing.join.joinService': 'Join concepts as a single service (accumulate amounts in a single service, it is only possible if you share the same SAT concept and description)',
  'conciliaciones.text': 'Conciliations',
  'conciliaciones.abonos.text': 'Credits',
  'conciliaciones.concepto.text': 'Concept',
  'conciliaciones.referencia.text': 'Reference',
  'conciliaciones.referenciaAmpliada.text': 'Expanded reference',
  'conciliaciones.fecha.text': 'Date',
  'conciliaciones.fecha.text.error': 'Incorrect format fertilizer date',
  'conciliaciones.abono.text': 'Credit',
  'conciliaciones.cargos.text': 'Charges',
  'conciliaciones.disponible.text': 'Available',
  'conciliaciones.cliente.text': 'Client',
  'conciliaciones.empresa.text': 'Transmitter',
  'conciliaciones.estatus.text': 'Payment status',
  'conciliaciones.afavor.text': 'With balance available',
  'conciliaciones.sinsaldo.text': 'No balance available',
  'conciliaciones.edit.text': 'Edit conciliation',
  'conciliaciones.abono.error': 'The fertilizer does not have a valid value',
  'conciliaciones.cargos.add': 'Add charge',
  'issuer.input.fiel': 'FELL',
  'issuer.input.addFiel': 'Renew faithful',
  'issuer.input.uploadFiel': 'Load faithful',
  'issuer.input.deleteFiel': 'Eliminate',
  'issuer.input.csd.info': 'It has CSD certificates to generate invoices',
  'issuer.input.csd.warning': 'In order to generate invoices, it is necessary to load the CSD documents',
  'issuer.input.fiel.info': 'It has the faithful to download invoices',
  'issuer.input.fiel.warning': 'In order to download invoices it is necessary',
  'menu.category.concepts': 'Product and services',
  'menu.category.concepts.myConcepts': 'My products and services',
  'billing.actions.preview': 'Preview',
  'billing.cancelDraftModal.titleOnCreate': 'Do you want to cancel the creation of the invoice?',
  'billing.cancelDraftModal.titleOnEdit': 'Do you want to cancel the bill edition?',
  'billing.cancelDraftModal.content': 'If you cancel all the information will be lost',
  'billing.cancelDraftModal.okTextOnCreate': 'Cancel draft',
  'billing.cancelDraftModal.okTextOnEdit': 'Yes, close',
  'billing.cancelDraftModal.cancelText': 'Close',
  // Invoices Importer
  'billingDraft.import.upload.title': 'Your bills import',
  'billingDraft.import.upload.description': 'If you already have an invoice base here we tell you how to upload the data to Stampi in an easy, fast and safe way.',
  'billingDraft.import.retry.title': 'Import problems',
  'billingDraft.import.retry.description': 'A problem occurred when saving the invoices, download the file to review and correct the ranks that have errors.',
  'billingDraft.import.success.title': 'Correct import',
  'billingDraft.import.success.description': 'Import has been made satisfactorily.',
  'billingDraft.import.stepOne.title': 'paso1',
  'billingDraft.import.stepOne.description': 'Download the .ZIP file that contains the following documents: template, fast guide for how to fill the document and example template.',
  'billingDraft.import.stepTwo.title': 'paso2',
  'billingDraft.import.stepTwo.description': 'Upload the file with the invoices you want to add, the file must have the base of the downloaded template.',
  'billingDraft.import.btn.downloadTemplate': 'Download template',
  'billingDraft.import.btn.downloadTemplate.errors': 'Download template with errors',
  'billingDraft.import.btn.downloadTemplate.report': 'Download import detail',
  'billingDraft.import.btn.import': 'Matter',
  'billingDraft.import.btn.importing': 'Importing file',
  'billingDraft.import.btn.cancel': 'Cancel',
  'billingDraft.import.results.invoicesFound': 'Invoices found',
  'billingDraft.import.results.invoicesSaved': 'Stored invoices',
  'billingDraft.import.results.invoicesWithErrors': 'Invoices with errors',
  'modal.delete.concept.warning': 'The action that is about to perform cannot be undone.The product or service will be eliminated.',
  'modal.delete.template.warning': 'The action that is about to perform cannot be undone.The template "{Templatename}" will be eliminated',
  'modal.delete.team.warning': 'The action that is about to perform cannot be undone.',
  // Conceptos
  'concept.title': 'Products and services',
  'concept.subtitle': 'Define product or service descriptions and its price per unit',
  'concept.label.descritionConcept': 'Description',
  'concept.label.codeProducServ': 'Key code or product',
  'concept.label.codeProducServ.placeholder': 'Capture the concept code',
  'concept.label.codeProducServ.error': 'The concept code is incorrect',
  'concept.label.descProdServ': 'KEY DESCRIPTION PRODUCT',
  'concept.label.codeUnit': 'Unit code',
  'concept.label.descCodeUnit': 'DESCRIPTION UNIT',
  'concept.label.unitValue': 'Unit price',
  'concept.label.amountTax': 'total',
  'concept.label.symbol': 'Symbol',
  'concept.label.company': 'Company',
  'concept.label.barCode': 'Barcode',
  'concept.label.sku': 'sku (stockKeepingUnit)',
  'search.concepts.placeholder': 'Search by product name, description, key (product - SAT) or unit',
  'concept.add.txtButton': 'Add product or service',
  'concept.add.newTax': 'Can add a new tax',
  'concept.edit.txtButton': 'Edit concept',
  'concept.delete.txtButton': 'Eliminate concept',
  'concept.delete.title': 'Is this sure to eliminate',
  'concept.add.success.title': 'Added concept correctly',
  'concept.add.success.subtitle': 'This new concept is already registered in your products and services',
  'concept.updated.success.title': 'Updated concept correctly',
  'concept.updated.success.subtitle': 'This concept has been updated and you can see it in your products and services',
  'concept.form.descProdServ': 'Capture to search',
  'concept.form.descProdServ.message': 'I typed to start looking',
  'concept.form.descProdServ.error': 'The description of the product or service is required',
  'concept.form.description': 'Capture a description',
  'concept.form.description.placeholder': 'Ex: facial beauty products',
  'concept.form.description.message': 'Short text that explain the characteristics and particularities of the product or served',
  'concept.form.description.error': 'The description is required to register a product or served',
  'concept.form.quantity.message': 'The amount cannot be modified, its modification is allowed when making its invoice',
  'concept.form.unit.value.placeholder': 'ej:10000',
  'concept.form.unit.value.message': 'Corresponds to the price per unit of the product or served, it is necessary to apply some discount',
  'concept.form.unit.value.error': 'The unit price must be higher than zero to apply a discount type',
  'concept.form.typeDiscount.title': 'Discount type',
  'concept.form.typeDiscount.message': 'Select the discount type',
  'concept.form.discount.value.message': 'Product or service discount',
  'concept.form.discount.info': 'A unit price is required to apply a discount',
  'concept.form.barCode.placeholder': 'ej:7502103434890',
  'concept.form.barCode.message': 'Product or service barcode',
  'concept.form.sku.placeholder': 'ej:XyzBln41',
  'concept.form.sku.message': 'Identification code in the inventory, composed of alfanumerical way, that is, by letters and numbers',
  'concept.form.tooltip.description': 'You can search for a product/service or you can write a new description',
  'concept.form.tooltip.descriptionProdSerc': 'Write to start looking for the description of the product key',
  'concept.form.tooltip.amount': 'It refers to the multiplication of quantity by unit value',
  'concept.form.tooltip.amountTax': 'It refers to the amount plus the application of their respective taxes',
  'concept.form.tooltip.discount': 'There must be a unit value to be able to apply a discount, this should not be greater than the amount',
  'concept.form.error.message': 'Please verify the fields in the form',
  'concept.view.txtButton': 'see more',
  'concept.completeInfo': 'Full Information of the concept',
  'concept.view.title.create': 'Create Product / Service',
  'concept.view.title.update': 'Update product / service:',
  'concept.search.errorMessage': 'No results were found:',
  'concept.form.title.advanceConfigurate': 'Advanced configuration',
  'billing.input.paymentMethod.tooltip': 'Choose the PU payment method (payment in a single exhibition) or PDD (payment in partialities or deferred).',
  'billing.input.invoiceType.tooltip': 'Select between type invoices: income, graduate and transfer.',
  'billing.input.exchangeRate.tooltip': 'Capture an exchange rate with reference to the Mexican currency (MXN).',
  'billing.input.concept.unityKey.input.placeholder': 'Write or touch the button to start looking',
  'billing.input.concept.use.this': 'Use:',
  'billing.input.concept.description.search.placeholder': 'Find a product or capture a description',
  'billing.label.add.product': 'New product or service',
  'billing.label.collapse.general': 'General calculations',
  'billing.label.collapse.tranferred': 'Taxes transferred',
  'billing.label.collapse.witheld': 'Retained taxes',
  'billing.label.collapse.vat.tranferred': 'VAT Tax',
  'billing.label.collapse.vat.withheld': 'VAT retained tax',
  'billing.label.collapse.ieps.tranferred': 'IEPS Tax',
  'billing.label.collapse.isr.withheld': 'ISR Tax',
  'billing.input.concept.add': 'Add new concept',
  'billing.input.save.sinTimbrar': 'Save without stamping',
  'billing.input.save.edit': 'To edit',
  'billing.draft.submit.stamp.concept.title': 'UPDATE IN PRODUCTS AND SERVICES',
  'billing.draft.submit.stamp.concept.created': 'New item was added to your products and services, click here to see',
  'billing.draft.submit.stamp.concepts.createds': 'New items were added to your products and services, click here to see them',
  'billing.box.searching': 'Looking for records',
  'config.team.delete.txtButton': 'Delete equipment user',
  'generic.actions.empty': ' ',
  'company.name.description': 'You can change the name of your company at any time from the general configuration.',
  'company.description': 'A company is a grouping of customers and invoices in the software.You can also invite other people to collaborate in this account.',
  'metadata.subtitle': 'You can add more information for integration with other third -party platforms',
  'message.sendwhats.waiting': 'We are sending your WhatsApp bill, this can take a few seconds',
  'message.sendwhats.success': 'Whatsapp correctly sent to the number {Number}',
  'menu.add-stamps.text': 'Add more timbres',
  'step.template.create.text': 'Create template',
  'step.template.update.text': 'Update template',
  'history.purchasedCredits': 'Total acquired',
  'billing.label.condiciones-pago': 'Payment condition (optional)',
  'billing.input.condiciones-pago.tooltip': 'Terms agreed between buyer and seller if applied, example: number of days in which the payment will be made (15 days after the issuance, 12 months, etc).',
  'company.user.placeholder': 'Select the new company owner',
  'company.input.user.error': 'The user is mandatory',
  'company.input.user.warning': 'There are no more users to assign as owner',
  'profile.defaultCompany.text': 'Predetermined company',
  'profile.btn.wizard': 'verTutorial',
  'info.iva': 'Includes VAT',
  'info.expiration-1': 'Vence in',
  'info.expiration-2': 'He won',
  'info.today': 'Expires today',
  days: 'días',
  hours: 'horas',
  minutes: 'minutes',
  'request.add.text': 'New import',
  'request.update.text': 'Verify importation',
  fiel: 'FIEL',
  'rfc.receiver': 'rfcReceptor',
  'rfc.receiver.placeholder': 'Capture the RFC of the receiver',
  'request.solicitante': 'Requester',
  'request.receptor': 'rfcReceptor',
  'request.rango': 'Dates',
  'request.codigo.estado': 'Reply message',
  'request.estado': 'Status',
  'request.check.title': 'Do you want to verify the state of import?',
  'request.check.content': 'We will perform the verification to check if it is already possible to obtain the CFDi\'s, in case not; You will have to try later',
  'request.check.ok': 'To check',
  'menu.clients.details': 'Customer details',
  'client.title.update.success': 'Updated customer!',
  'verify.request.subtitle': 'It is necessary to load the files of the faithful and password to be able to initiate or verify the import',
  'total.invoices': 'total',
  'total.invoices.description': 'Total invoices downloaded',
  'request.solicitante.error': 'You must select the applicant',
  'request.solicitante.placeholder': 'Select the applicant',
  'request.filter.emisor': 'Bring invoices issued by the applicant',
  'rfc.issuer': 'RFC EMISOR',
  'rfc.issuer.placeholder': 'Capture the RFC of the issuer',
  'request.filter.receptor': 'Bring the invoices received by the applicant',
  'request.errores': 'See mistakes',
  'request.errores.title': 'The request presented one or more errors',
  'request.history': 'Update history',
  'request.history.title': 'Here is the history of the users who have made the requests',
  'request.rfcs.error': 'The applicant must be minimal as an issuer or receiver, but not both',
  'request.invoice.search': 'Search for RFC Social Razón',
  'request.receiver.search': 'RFC',
  'dont.close.window': 'Please do not close the window until the process concludes',
  'generate.zip': 'Generating the zip file',
  // Concept
  'concept.import.upload.title': 'Your products or services import',
  'concept.import.upload.description': 'If you already have a product/services base here we tell you how to upload the data to Stampi in an easily, fast and safe way.',
  'concept.import.retry.title': 'Correct import but there was {totalInvalidrows} {TotalInvalidrows, plural, one {error} other {errors}}}',
  'concept.import.retry.description': 'A problem occurred by adding some products/services, download the file to review the detail of the elements that could not be added satisfactorily.',
  'concept.import.success.title': 'Correct import',
  'concept.import.success.description': 'Import has been made satisfactorily.',
  'concept.import.stepOne.title': 'paso1',
  'concept.import.stepOne.description': 'Download the .ZIP file that contains the following documents: template, fast guide for how to fill the document and example template.',
  'concept.import.stepTwo.title': 'paso2',
  'concept.import.stepTwo.description': 'Upload the file with the products/services you want to add, the file must have the base of the downloaded template.',
  'concept.import.btn.downloadTemplate': 'Download template',
  'concept.import.btn.downloadTemplate.errors': 'Download template with errors',
  'concept.import.btn.import': 'Matter',
  'concept.import.btn.importing': 'Importing file',
  'concept.import.btn.cancel': 'Cancel',
  'concept.import.results.totalValidRows': 'Valid ranks',
  'concept.import.results.totalInvalidRows': 'Erroneous ranks',
  'concept.import.results.totalCreated': 'Created records',
  'concept.import.results.totalUpdated': 'Updated records',
  // Invoice Preview
  'preview.totals.amount': 'Imported:',
  'preview.totals.discount': 'Discounts:',
  'preview.totals.ivaTransferred': 'IVA T:',
  'preview.totals.ivaWitheld': 'IVA R:',
  'preview.totals.ieps': 'IEPS:',
  'preview.totals.isr': 'ISR:',
  'preview.totals.subtotal': 'Subtotal:',
  'preview.totals.total': 'Total:',
  'preview.taxes.exempt': 'Tax exempt',
  // wizard
  'wizard.modal.initial.title': 'Welcome!',
  'wizard.modal.initial.content': 'This fast guide will show you how you can broadcast your invoice to your starting customers and simplify your electronic billing process with Stampi.',
  'wizard.modal.clients.title': 'Customers',
  'wizard.modal.clients.content': 'Within this section you can manage all the clients you want to invoice in an easy and organized way.',
  'wizard.modal.issuers.title': 'Issuers',
  'wizard.modal.issuers.content': 'Within this section you can manage all the emitters with whom you want to generate your invoices in an easy and organized way.Remember that with Stampi the billing is not limited to a single issuer.',
  'wizard.modal.preinvoice.title': 'prefacturas',
  'wizard.modal.preinvoice.content': 'Within this section you can manage all the prefactures and see the history of the emails sent.',
  'wizard.modal.omit.title': 'Are you sure you want to omit the tutorial?',
  'wizard.modal.omit.content': 'Then you can activate it in the User Profile section> Preferences',
  'wizard.btn.start': 'Begin',
  'wizard.btn.continue': 'Continue',
  'wizard.btn.omit': 'To omit',
  'wizard.btn.omitConfirm': 'Yes, omit',
  'wizard.btn.goToSection': 'Go to the section',
  'wizard.btn.finish': 'Finish',
  'wizard.btn.newInvoice.title': 'Create your new bill',
  'wizard.btn.newInvoice.content': 'To start we must press the “Create Invoice” button where we will create a new one.',
  'wizard.btn.newInvoice.starContent': 'Click on the top button: "Create Invoice" to continue.',
  'wizard.btn.newClient.title': 'New client',
  'wizard.btn.newClient.content': 'To create one you must do it by pressing the "Add customer" button.',
  'wizard.btn.newIssuer.title': 'Create a new issuer',
  'wizard.btn.newIssuer.content': 'To create a new manual formal issuer you must do so by pressing the "Add Issuer" button.',
  'wizard.invoice.listClient.title': 'Customers',
  'wizard.invoice.listClient.content': 'Here is the list of customers who are the receptors of the invoices we issue.',
  'wizard.invoice.createClient.title': 'Create new customer',
  'wizard.invoice.createClient.content': 'You need to create a new client to select it as an option within this list.',
  'wizard.invoice.createdClient.title': 'Create new customer',
  'wizard.invoice.createdClient.content': 'When you select your new customer/receiver, the fields of CFDI, tax regime and zip code will be filled automatically.',
  'wizard.invoice.listIssuer.title': 'Issuers',
  'wizard.invoice.listIssuer.content': 'Here is the list of emitters of the invoices you generate.',
  'wizard.invoice.createIssuer.title': 'Create a new issuer',
  'wizard.invoice.createIssuer.content': 'Now, you need to create a new issuer to select it as an option within this list.',
  'wizard.invoice.createdIssuer.title': 'Issuers',
  'wizard.invoice.createdIssuer.content': 'Now you can select your new issuer on the list and automatically fill the fields of: tax regime, postal code, among other optional fields in the issuer\'s registry.',
  'wizard.invoice.generalInfo.title': 'General information',
  'wizard.invoice.generalInfo.content': 'To continue with the creation of the invoice, it is necessary to fill some general data of the invoice shown below and where the fields with (*) are mandatory.',
  'wizard.invoice.concepts.title': 'Conceptos',
  'wizard.invoice.concepts.content': 'Para continuar con la creación de la factura es necesario el llenado de los “Conceptos”, es decir, los productos o servicios por los cuales se está haciendo la factura y pueden agregarse múltiples, de ser necesario.',
  'wizard.invoice.sendEmail.title': 'Envío por correo',
  'wizard.invoice.sendEmail.content': 'Podrás enviar la prefactura por correo electrónico a tu cliente quien recibirá un enlace para validar la información de la prefactura, y en su caso, podrá agregar o modificar dichos datos previo al timbrado de la factura.',
  'wizard.invoice.save.title': 'Vista previa',
  'wizard.invoice.save.content': 'Una vez que ya se hayan completado todos los campos necesarios para crear la factura podrás elegir entre “Cancelar”, para cerrar la ventana y no guardar ninguno de los cambios realizados en la creación de la factura; o “Vista Previa” para continuar con la previsualización de la factura previo a su emisión y timbrado ante el SAT.',
  'wizard.clients.formHelp': 'Para agregar un nuevo cliente necesitas completar los siguientes datos del formulario donde los campos con (*) son obligatorios. Por ahora solo es un recorrido en la interfaz, posteriormente podrás crear un nuevo registro.',
  'wizard.issuers.formHelp': 'Para agregar un nuevo emisor necesitas completar los siguientes datos del formulario donde los campos con (*) son obligatorios. Por ahora solo es un recorrido en la interfaz, posteriormente podrás crear un nuevo registro.',
  // Preinvoice
  'preinvoice.loading': 'Cargando Factura',
  'preinvoice.stamped.title': 'Factura timbrada',
  'preinvoice.stamped.subtitle': 'El comprobande de la factura ha sido enviado a su correo electrónico.',
  'preinvoice.rejected.title': 'Solicitud enviada',
  'preinvoice.stampForm.checkbox.placeholder': 'Acepto que todos los datos que presenta esta factura son correctos',
  'preinvoice.stampForm.checkbox.error': 'Por favor marque la casilla',
  'preinvoice.stampForm.cancel': 'Solicitar corrección',
  'preinvoice.stampForm.edit': 'Modificar mis datos',
  'preinvoice.stampForm.submit': 'Timbrar mi factura',
  'preinvoice.stampForm.sending': 'Timbrando factura',
  'preinvoice.rejectForm.title': 'Solicitar corrección',
  'preinvoice.rejectForm.textarea.label': 'Motivo',
  'preinvoice.rejectForm.textarea.placeholder': 'Motivo por el cual desea solicitar la correción',
  'preinvoice.rejectForm.textarea.error': 'Por favor ingrese el motivo por el cual desea solicitar la correción',
  'preinvoice.rejectForm.submit': 'Enviar solicitud',
  'preinvoice.rejectForm.sending': 'Enviando solicitud',
  'preinvoice.rejectForm.cancel': 'Cancelar',
  'preinvoice.receiverForm.title.onCreate': 'Agregar mis datos',
  'preinvoice.receiverForm.title.onEdit': 'Modificar mis datos',
  'preinvoice.receiverForm.submit': 'Guardar mis datos',
  'preinvoice.receiverForm.sending': 'Guardando mis datos',
  'preinvoice.downloadFilesForm.rfc.label': 'RFC',
  'preinvoice.downloadFilesForm.rfc.subtitle': 'Si deseas descargar los comprobantes de la factura llena el siguiente formulario',
  'preinvoice.downloadFilesForm.rfc.placeholder': 'Ingresa tu RFC',
  'preinvoice.downloadFilesForm.rfc.error': 'Por favor ingresa tu RFC',
  'preinvoice.downloadFilesForm.submit': 'Verificar RFC',
  'preinvoice.downloadFilesForm.sending': 'Verificando RFC',
  'preinvoice.download.pdf': 'Descargar PDF',
  'preinvoice.download.xml': 'Descargar XML',
  'preinvoice.xml.waiting': 'Estamos generando el documento XML esto puedo demorar unos segundos',
  // Template
  'template.action.create': 'Nueva plantilla creada',
  'template.action.delete': 'Plantilla eliminada',
  'template.action.update': 'Plantilla actualizada',
  'template.add.txtButton': 'Nueva plantilla',
  'template.btn.data.empty': 'Creemos tu primer plantilla',
  'template.concepts.create.first': 'Creemos tu primer concepto',
  'template.concepts.empty': 'Sin conceptos (productos o servicios) asociados a esta plantilla',
  'template.concepts.modified': 'Creemos que usted ha modificado la información del concepto, si esto es correcto por favor de clic en aceptar para actualizar la información del concepto.',
  'template.concepts.search': 'Busquemos un concepto (producto o servicio) ',
  'template.create': 'Creando nueva plantilla  "{templateName}"',
  'template.data.empty': 'Sin plantillas registradas',
  'template.data.name': 'Nombre plantilla',
  'template.data.search': 'Busca por nombre, emisor, receptor, concepto ó total',
  'template.data.type': 'Se refiere al tipo de plantilla. Rapida: plantilla que puede utilizarse en "facturas rapidas" y se compone de un emisor + IVA Trasladado, Recurrente: plantilla que se utiliza en "Crear factura" de forma mas general y detallada',
  'template.delete': 'Borrando plantilla  "{templateName}"',
  'template.import.txtButton': 'Importar',
  'template.input.name.error': 'El nombre de la plantilla es obligatorio',
  'template.input.name.placeholder': 'Establezca el nombre de la plantilla',
  'template.input.name.text': 'Nombre de la plantilla',
  'template.select.issuer': 'Seleccione emisor',
  'template.select.label': 'Plantillas',
  'template.select.placeholder': 'Seleccione una plantilla',
  'template.select.receiver': 'Seleccione receptor',
  'template.subtitle.concepts': 'Podemos buscar conceptos (productos y servicios) que tengas registrados y adjuntarlos a esta plantilla',
  'template.subtitle.config': 'Establezca la configuración para poder reutilizarla en facturas posteriores',
  'template.subtitle.persons': 'Seleccione a las personas {icon} que desee establecer en esta platilla',
  'template.subtitle': 'Establezca un nombre a su nueva plantilla',
  'template.title.config': 'Configuración de la factura',
  'template.title.persons': 'Personas',
  'template.update': 'Actualizando plantilla  "{templateName}"',
  // Fast Invoice
  'fast.invoice.step.one.title': 'Selecciona plantilla',
  'fast.invoice.step.one.modal.subtitle': 'Selecciona una plantilla rápida para esta prefactura',
  'fast.invoice.step.one.input.search.placeholder': 'Pulsa una tecla para empezar a buscar',
  'fast.invoice.step.one.input.search.searching': 'Buscando...',
  'fast.invoice.step.two.title': 'Ingresa monto total',
  'fast.invoice.step.two.modal.title': 'Monto total a facturar',
  'fast.invoice.step.two.modal.subtitle': 'Establece el monto total de esta prefactura',
  'fast.invoice.step.three.modal.option.1.title': 'Número celular',
  'fast.invoice.step.three.modal.option.1.subtitle': 'Establece el número celular al que haremos llegar la prefactura',
  'fast.invoice.step.three.modal.option.2.title': 'Correo electrónico',
  'fast.invoice.step.three.modal.option.2.subtitle': 'Establece el correo al que haremos llegar la prefactura',
  'fast.invoice.step.three.title': 'Elige canal de envío',
  'fast.invoice.step.three.modal.channel': 'Elige un canal de envío',
  'fast.invoice.step.four.title': 'Verifica y envía',
  'fast.invoice.step.four.modal.title': 'Envió de factura',
  'fast.invoice.step.four.modal.subtitle': 'Antes de enviar por favor verifica si todos los datos de tu factura son correctos al igual que el número celular.',
  'fast.invoice.send': 'Enviar prefactura',
  'fast.invoice.new': 'Nueva prefactura rápida',
  'fast.invoice.sent': 'Prefactura Enviada',
  'fast.invoice.description.general.information': 'Información general',
  // importador clientes
  'client.import.upload.title': 'Importa tus clientes',
  'client.import.upload.description': 'Si ya cuentas con una base de clientes, aquí te decimos cómo subir los datos a Stampi de manera fácil, rápida y segura.',
  'client.import.retry.title': 'Importación correcta pero hubo {totalInvalidRows} {totalInvalidRows, plural, one {error} other {errores}}',
  'client.import.retry.description': 'Ocurrio un problema al agregar algunos Clientes, descarga el archivo para revisar el detalle de las filas que no se pudieron agregar satisfactoriamente.',
  'client.import.success.title': 'Importación correcta',
  'client.import.success.description': 'La importación se ha realizado satisfactoriamente.',
  'client.import.stepOne.title': 'Paso 1',
  'client.import.stepOne.description': 'Descargar el archivo .zip que contiene lo siguientes documentos: Plantilla, guía rápida de como llenar el documento y plantilla de ejemplo.',
  'client.import.stepTwo.title': 'Paso 2',
  'client.import.stepTwo.description': 'Sube el archivo con los Clientes que deseas agregar, el archivo debe tener la base de la plantilla descargada.',
  'client.import.btn.downloadTemplate': 'Descargar plantilla',
  'client.import.btn.downloadTemplate.errors': 'Descargar plantilla con errores',
  'client.import.btn.import': 'Importar',
  'client.import.btn.importing': 'Importando archivo',
  'client.import.btn.cancel': 'Cancelar',
  'client.import.results.totalValidRows': 'Filas válidas ',
  'client.import.results.totalInvalidRows': 'Filas erróneas ',
  'client.import.results.totalCreated': 'Registros creados ',
  'client.import.results.totalUpdated': 'Registros actualizados ',
  'preinvoice.title': 'Prefacturas',
  'preinvoice.tag': 'Prefactura',
  'emailTracker.sender': 'Envió',
  'emailTracker.receiver': 'Recibió',
  'emailTracker.sendate': 'Fecha de envío',
  'emailTracker.open': 'Visto',
  'emailTracker.firstopen': 'Primera vez abierto',
  'emailTracker.lastopen': 'Última vez abierto',
  'emailTracker.totalviews': 'Veces visto',
  'emailTracker.rejected': 'Rechazada',
  'invoice.errors.title.concepts': '¡Revisa los conceptos!',
  'invoice.errors.title.client': '¡Falta información!',
  'invoice.errors.description.client': 'Solo es posible enviar la prefactura sin los datos del cliente, revisa la demás información',
  // Documentacion
  'documentatio.title': 'documentación',
  'documentation.api.txt': 'Conoce más acerca de lo que puedes hacer con la API de Stampi en nuestra',
  'documentation.webhook.txt': 'Conoce más acerca de lo que puedes hacer con los Webhooks de Stampi en nuestra',
  // Dashboard
  'dashboard.wait': 'Cargando Dashboard',
  'dashboard.noIssuers': 'Aun no tienes registrado ningún',
  'dashboard.create.issuer.btn': 'Crear un emisor',
  'dashboard.datepicker.placeholder': 'Selecciona el ejercicio',
  'dashboard.charts.invoice.subtitle': 'Gráficas de facturas timbradas y canceladas ',
  'dashboard.stamps.subtitle': 'Timbres utilizados según ejercicio seleccionado',
  'dashboard.charts.without.stamps': 'Sin facturas registradas (STAMPI)',
  'dashboard.charts.without.canceled': 'Sin facturas canceladas',
  'dashboard.charts.without.total': 'Sin registro de montos (STAMPI)',
  'dashboard.charts.without.total.sat': 'Sin registro de montos (SAT)',
  'dashboard.charts.title.amount': 'Montos',
  'dashboard.charts.amount.subtitle': 'Gráficas de dinero facturado, ingresos y egresos',
  'dashboard.charts.stampi': 'Montos Facturados (STAMPI)',
  'dashboard.charts.money': 'Montos Facturados (SAT)',
  'dashboard.import.success': 'Importación realizada',
  'dashboard.import.receiver': 'Recibidas',
  'dashboard.import.issued': 'Emitidas',
  'dashboard.import.lastSync': 'Fecha de realización de importación',
  'dashboard.import.downloaded.invoices': 'Facturas descargadas',
  'dashboard.import.verifying.import': 'Verificando importación',
  'dashboard.import.started': 'Importación iniciada',
  'dashboard.import': 'Importación',
  'dashboard.revenues': 'Ingresos',
  'dashboard.expenses': 'Egresos',
  'dashboard.utility': 'Utilidad',
  'dashboard.import.subtitle': 'Importación automatica de facturas registradas ante SAT',
  'sat.delete.fiel': 'Eliminar la FIEL después de descargar las facturas «solo se eliminará de la solicitud»',
  // Promociones
  'search.promotion.placeholder': 'Buscar por nombre o descripción',
  'promotion.delete.txtButton': 'Eliminar promoción',
  'modal.delete.promotion.warning': 'La acción que está a punto de realizar no se puede deshacer.',
  'promotion.add.txtButton': 'Agregar promoción',
  'promotion.edit.txtButton': 'Editar promoción',
  'promotion.input.name': 'Nombre de la promoción',
  'promotion.input.name.error': 'Por favor captura el nombre',
  'promotion.input.name.placeholder': 'Captura el nombre',
  'promotion.input.description': 'Cuerpo del mensaje',
  'promotion.input.description.error': 'Por favor captura la descripción',
  'promotion.input.description.max': 'La longitud maxima es de 100 caracteres',
  'promotion.input.description.placeholder': 'Captura la descripción (máximo 100 caracteres)',
  'promotion.input.image': 'Imagen',
  'promotion.input.image.placeholder': 'Cargar imagen',
  'promotion.input.image.drawerTitle': 'Haga clic o arrastre el archivo a esta área para cargarlo',
  'promotion.input.image.drawerDesc': 'Solo se permiten imágenes con los siguientes formatos: jpg, jpeg y png',
  'promotion.input.url': 'Enlace',
  'promotion.input.url.error': 'Por favor introduce una URL válida',
  'promotion.input.url.placeholder': 'Captura el enlace',
  'promotion.input.clics': 'Total de clics',
  'place.import': 'Importer',
  'pue.update': 'Update PUE\'s',
  'client.contacts.upsert': 'Update contacts',
  'concepts.descriptions': 'Concepts',
  'concepts.descriptions.add': 'Add concept',
  'concepts.descriptions.update': 'Update concept',
  'billing.input.concept.unityKey.desc.error': 'The description of the concept is required',
  'status.yes': 'Yes',
  'status.no': 'No',
  'cancel.charge': 'Are you sure to cancel the position?',
  'concepts.issuer.error': 'It is possible that the issuer does not have registered products',
  'concepts.issuer.delete.content': 'Are you sure to eliminate the concept?',
  'issuer.isLaborUnion': 'Is it a workers\' union?',
  'issuer.isLaborUnion.placeholder': 'Select whether it is a workers\' union',
  'issuer.idPlan': 'idPlan',
  'issuer.idPlan.placeholder': 'Capture the ID Plan',
  'input.yes': 'Yes',
  'input.no': 'No',
  'modal.billing.fromSAT.description': 'The action that is about to perform will generate a new draft with the information of the invoice downloaded by the SAT.',
  'verify.certs.title': 'Do you want to verify the expiration dates?',
  'verify.certs.content': 'We will perform the verification to check if the expiration dates of your certificates are correct.',
  'verify.certs.ok': 'To check',
  csdExpirationDate: 'CSD Expiration Date',
  fielExpirationDate: 'Faithful expiration date',
};

export default {
  locale: 'en-US',
  localeAntd,
  messages,
};
