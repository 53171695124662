import localeAntd from 'antd/es/locale/es_ES';

const messages = {
  // Generals
  'add.txt': 'Agregar',
  'edit.txt': 'Editar',
  // # TopBar
  'topBar.issuesHistory': 'Historial de problemas',
  'topBar.projectManagement': 'Gestión de proyectos',
  'topBar.typeToSearch': 'Buscar...',
  'topBar.findPages': 'Buscar páginas...',
  'topBar.actions': 'Acciones',
  'topBar.status': 'Estatus',
  'topBar.profileMenu.hello': 'Hola',
  'topBar.profileMenu.billingPlan': 'Plan de facturación',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Correo electrónico',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Editar configuración de usuario',
  'topBar.profileMenu.logout': 'Cerrar sesión',
  // # Search
  'topBar.search.searchApp': 'Buscar dentro de la aplicación',
  'topBar.search.pressEnter': 'Presiona enter para buscar',
  'topBar.search.noResults': 'No se han encontrado resultados',
  'topBar.search.results': 'Resultados de la busqueda',
  // # Agregar Factura
  'topBar.billing.title': 'Crear factura',
  'topBar.billing.add': 'Crear nueva factura',
  'topBar.billing.fromCanceled': 'Crear factura desde cancelada',
  'topBar.billing.paymentComplement': 'Aplicar complemento de pago',
  // # Locale
  'locale.key': 'es',
  'locale.es-MX': 'Español',
  'locale.en-US': 'Inglés',
  // # Aplicación
  'app.name': ' ',
  'app.nickname': 'Stampi',
  // # Login
  'login.successfully.message': 'Sesión iniciada',
  'login.successfully.description': '¡Has iniciado sesión correctamente!',
  'login.resetPassword.confirm.message': 'Solicitud envíada',
  'login.resetPassword.confirm.description': 'La solicitud del cambio de contraseña se ha generado, revisa tu correo para poder actualizar la contraseña',
  'login.resetPassword.success.message': 'Contraseña modificada',
  'login.resetPassword.success.description': 'La contraseña se actualizó, ya puedes iniciar sesión',
  // # Nueva cuenta
  'register.successfully.message': 'Registro correcto',
  'register.successfully.description': '¡Te has registrado correctamente!',
  // # Recuperar Contraseña
  'forgotPassword.title': 'Restablecer contraseña',
  'forgotPassword.emailMessage': 'Por favor captura tu correo electrónico',
  'forgotPassword.emailPlaceholder': 'Correo electrónico',
  'forgotPassword.button': 'Restablecer contraseña',
  'forgotPassword.back': 'Ir a iniciar sesión',
  'forgotPassword.password': 'Contraseña',
  'forgotPassword.confirmPassword': 'Confirmar contraseña',
  // # Inicio
  'index.title': 'Bienvenido a',
  'index.signIn': 'Inicia sesión con tu cuenta',
  'index.emailMessage': 'Por favor captura tu dirección de correo electrónico',
  'index.emailPlaceholder': 'usuario@email.com',
  'index.passwordMessage': 'Por favor captura tu contraseña',
  'index.passwordPlaceholder': '***************',
  'index.signInButton': 'Iniciar sesión',
  'index.forgotPassword': '¿Olvidaste tu contraseña?',
  'index.dontHaveAccount': '¿Aún no tienes una cuenta?',
  'index.signUpButton': 'Crear cuenta',
  // # Nuevo usuario
  'register.title': 'Aquí puedes crear tu cuenta',
  'register.description':
    'Para crear tu cuenta necesitamos que nos proporciones la siguiente información',
  'register.nameMessage': 'Please input your full name',
  'register.namePlaceholder': 'Nombre completo',
  'register.emailMessage': 'Please input your e-mail address',
  'register.emailPlaceholder': 'Correo electrónico',
  'register.passwordMessage': 'Please input your password',
  'register.passwordPlaceholder': 'Contraseña',
  'register.signUp': 'Crear cuenta',
  'register.terms01': 'Al registrarse damos por hecho que aceptas los',
  'register.terms02': 'términos de servicio',
  'register.terms03': 'y la',
  'register.terms04': 'política de privacidad',
  'register.alreadyHaveAccount': '¿Ya tienes una cuenta?',
  'register.signIn': 'Iniciar sesión',
  // # Menú
  'menu.home': 'Inicio',
  'menu.category.dashboard': 'Dashboard',
  'menu.category.options': 'Opciones',
  'menu.category.billing': 'Facturas',
  'menu.category.billing.draft': 'Borradores',
  'menu.category.billing.stamped': 'Timbradas',
  'menu.category.billing.canceled': 'Canceladas',
  'menu.category.billing.pending': 'Pendientes de cancelar',
  'menu.category.billing.ppd': 'Pagos en parcialidades',
  'menu.category.bank': 'Banco',
  'menu.category.bank.accountStatements': 'Estados de cuenta',
  'menu.category.bank.myAccounts': 'Mis cuentas',
  'menu.category.bank.associateInvoiceMovements': 'Asociar movimientos',
  'menu.category.clients': 'Clientes',
  'menu.category.clients.myClients': 'Mis clientes',
  'menu.category.transmitter': 'Emisores',
  'menu.category.transmitter.myIssuers': 'Mis emisores',
  'menu.category.setting': 'Configuración',
  'menu.category.setting.users': 'Usuarios',
  'menu.category.setting.myTeam': 'Mi equipo',
  'menu.category.profile': 'Configuración de usuario',
  'menu.category.accounts.receivable': 'SAT',
  'menu.category.accounts.payable': 'Cuentas por pagar',
  'menu.category.solicitude': 'Importaciones',
  'menu.category.preinvoice': 'Prefactura',
  'menu.category.promotion': 'Promociones',
  'menu.category.promotion.myPromotions': 'Mis promociones',
  // # Acciones
  'billing.actions.add': 'Agregar factura',
  'billing.actions.edit': 'Editar factura',
  'billing.actions.transmitter': 'Emisor',
  'billing.actions.receptor': 'Receptor',
  'billing.actions.total': 'Total',
  'billing.actions.progress': 'Progreso',
  'billing.actions.date': 'Fecha expedición',
  'billing.actions.updatedAt': 'Última edición',
  'billing.actions.actions': 'Acciones',
  'billing.actions.pay': 'Pago',
  'billing.actions.cancel': 'Cancelar',
  'billing.actions.submit': 'Guardar',
  'billing.menu.copy': 'Copiar UUID',
  'billing.menu.copy.message': 'El UUID se copió correctamente',
  'billing.menu.edit': 'Editar',
  'billing.menu.view': 'Ver detalles',
  'billing.menu.upload': 'Subir documentos',
  'billing.menu.download': 'Descargar',
  'billing.menu.downloadXML': 'Descargar XML',
  'billing.menu.downloadPDF': 'Descargar PDF',
  'billing.menu.downloadZIP': 'Descargar ZIP',
  'billing.menu.sendEmail': 'Enviar por correo',
  'billing.menu.sendEmisor': 'Enviar a emisor',
  'billing.menu.sendReceptor': 'Enviar a receptor',
  'billing.menu.sendOtros': 'Enviar a otros',
  'billing.menu.sendBilling': 'Enviar factura',
  'billing.menu.cancel': 'Cancelar',
  'billing.folio': 'Folio',
  'billing.folio.copy.message': 'El folio se copió correctamente',
  'billing.invoice.relation': 'Generar factura relacionada',
  'billing.menu.downloadAcuse.xml': 'Acuse de cancelación (XML)',
  // # Helmet
  'helmet.lockscreen': 'Pantalla bloqueada',
  'helmet.page404': 'Error #404',
  'helmet.page500': 'Error #500',
  'helmet.login': 'Iniciar sesión',
  // # Páginas de error
  'error.message': 'Error',
  'pages.error.notFound': 'Página no encontrada',
  'pages.error.serverError': 'Error del servidor',
  'pages.error.description': 'Esta página esta obsoleta, eliminada o no existe en absoluto',
  'pages.error.goBack': 'Regresar',
  // # Table
  'table.pagination.of': 'de',
  'table.pagination.items': 'registros',
  // # Otros
  'backtop.title': 'Subir',
  'empty.title': 'Sin registros',
  // # Custom Components
  'custom.rfc': 'RFC',
  'custom.rfc.name': 'Nombre',
  'custom.rfc.address': 'Dirección',
  'custom.rfc.phone': 'Teléfono',
  'custom.rfc.mobilePhone': 'Celular',
  'custom.rfc.email': 'Email',
  'custom.rfc.paidOut': 'Pagado',
  'custom.rfc.charged': 'Cobrado',
  'custom.rfc.invoiced': 'Facturado',
  'custom.rfc.viewDetails': 'Ver detalles',
  'custom.rfc.viewContact': 'Ver otro contacto',
  'custom.rfc.hideContacts': 'Ocultar contactos',
  'custom.rfc.openWhatsapp': 'Abrir con Whatsapp',
  'custom.rfc.call': 'Llamar',
  'custom.rfc.copyPhone': 'Copiar número',
  'custom.rfc.copyPhone.message': 'El número se copió correctamente',
  'custom.rfc.copyRFC.message': 'El RFC se copió correctamente',
  'custom.rfc.goMap': 'Ver mapa',
  'custom.rfc.copyAddress': 'Copiar dirección',
  'custom.rfc.copyAddress.message': 'La dirección se copió correctamente',
  // Ventanas de confirmación
  'modal.error.useModal': 'Debes iniciar el hook useModal con { intl }',
  'modal.title': 'Título del modal',
  'modal.content': 'Contenido del modal',
  'modal.onText': 'Aceptar',
  'modal.cancelText': 'Cancelar',
  'modal.delete.title': '¿Está seguro de eliminar?',
  'modal.delete.clients.warning':
    'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar clientes que no tengan ningún movimiento.',
  'modal.delete.clients.error': 'Lamentablemente no hemos podido eliminar correctamente al cliente',
  'modal.delete.issuer.warning':
    'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar emisores que no tengan ningún movimiento.',
  'modal.delete.issuer.error': 'Lamentablemente no hemos podido eliminar correctamente al emisor',
  'modal.delete.okText': 'Eliminar',
  'modal.delete.cancelTxt': 'Cerrar',
  'modal.billing.cancel.okText': 'Cancelar',
  'modal.billing.cancel.title': '¿Está seguro de cancelar?',
  'modal.billing.cancel.description': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden cancelar facturas timbradas',
  'modal.billing.cancel.error': 'Lamentablemente no hemos podido cancelar la factura',
  'modal.billing.cancel.success': 'La factura se canceló',
  'modal.billing.delete.description': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar facturas en borradores',
  'modal.billing.fromCancel.title': '¿Está seguro de generar una nueva factura?',
  'modal.billing.fromCancel.description': 'La acción que está a punto de realizar generará un nuevo borrador con la información de la factura',
  'modal.billing.fromCancelText': 'Generar',
  // Clientes
  'client.add.txtButton': 'Agregar cliente',
  'client.edit.txtButton': 'Editar cliente',
  'client.delete.txtButton': 'Eliminar cliente',
  'client.input.clientCode': 'Código cliente',
  'client.input.clientCode.placeholder': 'Captura el código del cliente',
  'client.input.clientCode.error': 'El código del cliente esta incorrecto',
  'client.input.logo': 'Logotipo',
  'client.input.profilePicture': 'Foto de perfil',
  'client.input.profilePicture.placeholder': 'Cargar imagen',
  'client.input.profilePicture.error': 'Ocurrió un error al cargar la imagen',
  'client.input.tradeName': 'Nombre comercial',
  'client.input.tradeName.placeholder': 'Captura el nombre comercial',
  'client.input.tradeName.error': 'El nombre comercial es obligatorio',
  'client.input.businessName': 'Razón social',
  'client.input.businessName.placeholder': 'Captura la razón social',
  'client.input.businessName.error': 'La razón social es obligatorio',
  'client.input.rfc': 'RFC',
  'client.input.rfc.placeholder': 'Captura el RFC',
  'client.input.rfc.error': 'Es obligatoro la captura de un RFC con el formato correcto',
  'client.input.companyId': 'Compañía',
  'client.input.companyId.placeholder': 'Selecciona la compañía',
  'client.input.companyId.error': 'Necesita seleccionar una compañía válida',
  'client.input.postalCode': 'Código postal',
  'client.input.postalCode.placeholder': 'Capture un código postal',
  'client.input.postalCode.error':
    'Es obligatoro la captura de un código postal con el formato correcto',
  'client.input.useCfdiId': 'Uso del CFDi',
  'client.input.useCfdiId.placeholder': 'Capture un uso del CFDi',
  'client.input.useCfdiId.error': 'El uso del CFDi es obligatorio',
  'client.input.contact': 'Contacto',
  'client.input.addContact': 'Agregar otro contacto',
  'client.input.deleteContact': 'Eliminar contacto',
  'client.input.contact.fullName': 'Nombre completo',
  'client.input.contact.fullName.placeholder': 'Capture un nombre completo',
  'client.input.contact.fullName.error': 'El nombre completo es obligatorio',
  'client.input.contact.email': 'Correo electrónico',
  'client.input.contact.email.placeholder': 'Capture un correo electrónico',
  'client.input.contact.email.error':
    'Es obligatoro la captura de un correo electrónico con el formato correcto',
  'client.input.contact.address': 'Domicilio',
  'client.input.contact.address.placeholder': 'Capture un domicilio',
  'client.input.contact.address.error': 'El domicilio es obligatorio',
  'client.input.contact.lada': 'Lada',
  'client.input.contact.lada.placeholder': 'Capture una lada',
  'client.input.contact.lada.error': 'La lada es obligatorio',
  'client.input.contact.officePhone': 'Teléfono',
  'client.input.contact.officePhone.placeholder': 'Capture un Teléfono',
  'client.input.contact.officePhone.error': 'El número de teléfono no tiene un formato correcto',
  'client.input.contact.cellPhone': 'Celular',
  'client.input.contact.cellPhone.placeholder': 'Capture un celular',
  'client.input.contact.cellPhone.error': 'El  número de celular no tiene un formato correcto',
  'client.input.contact.linkedIn': 'LinkedIn',
  'client.input.contact.linkedIn.error': 'El LinkedIn es obligatorio',
  'client.input.contact.facebook': 'Facebook',
  'client.input.contact.facebook.error': 'El Facebook es obligatorio',
  'client.input.contact.twitter': 'Twitter',
  'client.input.contact.twitter.error': 'El Twitter es obligatorio',
  'client.input.contact.instagram': 'Instagram',
  'client.input.contact.instagram.error': 'El Instagram es obligatorio',
  'client.input.contact.url': 'URL',
  'client.input.contact.url.placeholder': 'Capture una URL',
  'client.input.contact.url.error': 'La URL es obligatorio',
  'client.input.placeID': 'PlaceID',
  // Emisores
  'issuer.add.txtButton': 'Agregar emisor',
  'issuer.edit.txtButton': 'Editar emisor',
  'issuer.delete.txtButton': 'Eliminar emisor',
  'issuer.buyBillingStamps.txtButton': 'Mis timbres',
  'issuer.buyBillingStamps.input.creditsNumber': 'Numero de créditos',
  'issuer.buyBillingStamps.input.creditsNumber.placeholder': 'Capture el número de créditos',
  'issuer.buyBillingStamps.input.creditsNumber.error': 'El numero de creditos es incorrecto',
  'issuer.purchaseHistory.table.createdAt': 'Fecha de compra',
  'issuer.purchaseHistory.table.credits': 'Timbres',
  'issuer.purchaseHistory.table.endDate': 'Fecha de caducidad',
  'issuer.purchaseHistory.table.status': 'Estatus',
  'issuer.purchaseHistory.table.title': 'Historial de compras',
  'issuer.input.taxRegimeId': 'Régimen fiscal',
  'issuer.input.taxRegimeId.placeholder': 'Capture un régimen fiscal',
  'issuer.input.taxRegimeId.error': 'El régimen fiscal es obligatorio',
  'issuer.input.keyFile': 'Archivo .key',
  'issuer.input.keyFile.placeholder': 'Agregue el archivo .key',
  'issuer.input.keyFile.error': 'El archivo .key es obligatorio',
  'issuer.input.password': 'Contraseña del .key',
  'issuer.input.password.placeholder': 'Capture la contraseña del .key',
  'issuer.input.password.error': 'La contraseña es obligatorio',
  'issuer.input.certFile': 'Archivo .cert',
  'issuer.input.certFile.placeholder': 'Agregue el archivo .cert',
  'issuer.input.certFile.error': 'El archivo .cert es obligatorio',
  'issuer.input.certificates': 'Certificados',
  'issuer.input.addCertificates': 'Renovar certificado',
  'issuer.input.deleteCertificates': 'Eliminar certificado',
  'issuer.input.series': 'Series',
  'issuer.input.series.placeholder': 'Agregue una o más series',
  'issuer.input.series.error': 'Se debe definir minímo una serie',
  'issuer.input.lastFolio': 'Último folio',
  'issuer.input.postalCode.placeholder': 'Defina el último folio',
  'issuer.input.postalCode.error': 'El último folio es obligatorio',
  'issuer.input.logo': 'Logotipo',
  'issuer.input.logo.placeholder': 'Agregue la imagen',
  'issuer.input.logo.error': 'La imagen de la factura es obligatoria',
  'issuer.input.responsible': 'Responsables',
  'issuer.input.responsible.placeholder': 'Elija uno o más responsables',
  'issuer.input.responsible.error': 'Debe definir minímo un responsable',
  // Billing
  'billing.group.name': 'Factura',
  'billing.input.issuer': 'Emisor',
  'billing.input.issuer.placeholder': 'Seleccione un emisor',
  'billing.input.issuer.error': 'El emisor es obligatorio',
  'billing.input.client': 'Receptor',
  'billing.input.client.placeholder': 'Seleccione un receptor',
  'billing.input.client.error': 'El receptor es obligatorio',
  'billing.input.paymentForm': 'Forma de pago',
  'billing.input.paymentForm.placeholder': 'Seleccione una forma de pago',
  'billing.input.paymentForm.error': 'La forma de pago es obligatoria',
  'billing.input.paymentMethod': 'Método de pago',
  'billing.input.paymentMethod.placeholder': 'Seleccione un método de pago',
  'billing.input.paymentMethod.error': 'El método de pago es obligatorio',
  'billing.input.invoiceType': 'Tipo de factura',
  'billing.input.invoiceType.placeholder': 'Seleccione un tipo de factura',
  'billing.input.invoiceType.error': 'El tipo de factura es obligatorio',
  'billing.input.serial': 'Serie',
  'billing.input.serial.placeholder': 'Seleccione una serie',
  'billing.input.serial.error': 'La serie es obligatoria',
  'billing.input.folio': 'Folio',
  'billing.input.folio.placeholder': 'Seleccione un folio',
  'billing.input.folio.error': 'El folio es obligatorio',
  'billing.input.currency': 'Moneda',
  'billing.input.currency.placeholder': 'Seleccione una moneda',
  'billing.input.currency.error': 'La moneda es obligatoria',
  'billing.input.exchangeRate': 'Tipo de cambio',
  'billing.input.exchangeRate.placeholder': 'Captura un tipo de cambio',
  'billing.input.exchangeRate.error': 'El tipo de cambio es obligatorio y debe tener un formato correcto',
  'billing.input.concepts': 'Conceptos',
  'billing.input.concept': 'Concepto',
  'billing.input.addConcept': 'Agregar otro concepto',
  'billing.input.deleteConcept': 'Eliminar concepto',
  'billing.input.concept.productKey': 'Clave de producto o servicio',
  'billing.input.concept.productKey.desc': 'Descripción de la clave de producto o servicio',
  'billing.input.concept.productKey.placeholder': 'Seleccione una clave de producto o servicio',
  'billing.input.concept.productKey.error': 'La clave de producto o servicio no tiene el formato correcto',
  'billing.input.concept.unityKey': 'Clave de unidad',
  'billing.input.concept.unityKey.desc': 'Descripción de la clave de unidad',
  'billing.input.concept.unityKey.placeholder': 'Seleccione una clave de unidad',
  'billing.input.concept.unityKey.error': 'La clave de unidad es obligatoria',
  'billing.input.concept.quantity': 'Cantidad',
  'billing.input.concept.quantity.placeholder': 'Captura una cantidad',
  'billing.input.concept.quantity.error': 'La cantidad es obligatoria',
  'billing.input.concept.unity': 'Unidad',
  'billing.input.concept.unity.placeholder': 'Seleccione una unidad',
  'billing.input.concept.unity.error': 'La unidad es obligatoria',
  'billing.input.concept.description': 'Descripción',
  'billing.input.concept.description.placeholder': 'Captura una descripción',
  'billing.input.concept.description.error': 'La descripción es obligatoria',
  'billing.input.concept.unitValue': 'Valor unitario',
  'billing.input.concept.unitValue.placeholder': 'Captura un valor unitario',
  'billing.input.concept.unitValue.error': 'El valor unitario es obligatoria',
  'billing.input.concept.amount': 'Importe',
  'billing.input.concept.amount.placeholder': 'Captura un importe',
  'billing.input.concept.amount.error': 'El importe debe ser mayor o igual a 0',
  'billing.input.concept.discount': 'Descuento',
  'billing.input.concept.discount.title': 'Descuentos',
  'billing.input.concept.discount.type': 'Tipo descuento',
  'billing.input.concept.discount.placeholder': 'Captura un descuento',
  'billing.input.concept.discount.error': 'El descuento es obligatorio',
  'billing.open.catalog.prodserv': 'Abrir catálogo de productos y servicios',
  'billing.open.catalog.unidades': 'Abrir catálogo de unidades',
  'billing.open.catalog.prodserv.search': 'Captura una palabra',
  'billing.submit.error.without.concepts': 'No se detectaron conceptos válidos',
  'billing.submit.success': 'Se ha generado la factura correctamente',
  'billing.concept.success': 'Se actualizo la información correctamente',
  'billing.input.generalData.title': 'Datos generales',
  'billing.input.impuesto.title': 'Impuestos',
  'billing.input.impuesto.type': 'Tipo impuesto',
  'billing.input.impuesto.type.placeholder': 'Selecciona el tipo de impuesto',
  'billing.input.impuesto.type.error': 'Es necesario seleccionar un tipo de impuesto',
  'billing.input.impuesto.impuesto': 'Impuesto',
  'billing.input.impuesto.impuesto.placeholder': 'Selecciona el impuesto',
  'billing.input.impuesto.impuesto.error': 'Es necesario seleccionar un impuesto',
  'billing.input.impuesto.tasaOCuota': '¿Tasa o cuota?',
  'billing.input.impuesto.tasaOCuota.placeholder': 'Selecciona la tasa o cuota',
  'billing.input.impuesto.tasaOCuota.error': 'Es necesario seleccionar una tasa o cuota',
  'billing.input.impuesto.valorTasaOcuota': 'Valor tasa o cuota',
  'billing.input.impuesto.valorTasaOcuota.placeholder': 'Capture un valor de la tasa o cuota',
  'billing.input.impuesto.valorTasaOcuota.error': 'Es necesario capturar un valor de la tasa o cuota',
  'billing.input.impuesto.add': 'Agregar impuesto',
  'billing.input.impuesto.remove': 'Quitar  impuesto',
  'billing.input.impuesto.error': 'El impuesto a agregar no es válido',
  'billing.input.total': 'Total',
  'billing.input.subtotal': 'Subtotal',
  'billing.input.retenciones': 'Total impuestos retenidos',
  'billing.input.traslados': 'Total impuestos trasladados',
  'billing.input.retenido': 'Retenido',
  'billing.input.trasladado': 'Trasladado',
  'billing.input.concept.update': 'Actualizar información del concepto',
  'billing.subtitle.receptor': 'Selecciona la entidad que recibirá la factura, el RFC y código postal son informativos. El uso del CFDi se obtiene del receptor, pero puede modificarse.',
  'billing.subtitle.emisor': 'Selecciona la entidad que emitirá la factura, el RFC y código postal son informativos. El régimen fiscal se obtiene del emisor, pero puede modificarse.',
  'billing.subtitle.factura': 'Captura la información general de la factura, la serie y folio son opcionales.',
  'billing.subtitle.conceptos': 'Agrega los conceptos a facturar. Para detallar la información da clic en el símbolo: ( > ). La unidad predeterminada es Pieza.',
  'billing.subtitle.generales': 'Puedes personalizar la unidad, asignar la clave de unidad SAT y definir un descuento por porcentaje o valor fijo.',
  'billing.subtitle.impuestos': 'Define los impuestos del concepto. Para agregar un impuesto presiona la tecla "Enter" al definir el valor de la tasa o cuota',
  'billing.helper.currency': 'Si no facturas en pesos aquí define la divisa',
  'billing.helper.exchangeRate': 'Captura el valor actual de cotización de la moneda (en pesos mexicanos)',
  'billing.helper.footer.importe': 'La suma del resultado de multiplicar el valor unitario por la cantidad de cada concepto',
  'billing.helper.footer.descuento': 'La suma de todos los descuentos aplicados',
  'billing.helper.footer.subtotal': 'El resultado de restar los descuentos al importe (solo si hay descuentos)',
  'billing.helper.footer.total': 'La suma del subtotal más todos los impuestos retenidos',
  'billing.concept.error': 'Necesitas definir una clave de unidad SAT',
  'billing.input.emisor.addNew': 'Agregar nuevo emisor',
  'billing.input.receptor.addNew': 'Agregar nuevo receptor',
  'billing.input.emisor.edit': 'Editar emisor',
  'billing.input.receptor.edit': 'Editar receptor',
  'billing.input.fechaExpedicion': 'Fecha expedición',
  'billing.input.fechaExpedicion.error': 'La fecha es obligatoria',
  'billing.input.save.timbrar': 'Guardar y timbrar',
  'billing.draft.submit.create.success': 'Se agregó una factura a borradores',
  'billing.draft.submit.update.success': 'Se actualizó el borrador de la factura',
  'billing.draft.submit.stamp.success': 'Se generó el timbrado de la factura',
  'billing.draft.submit.stamp.initial': 'Se inició el proceso de timbrado',
  'billing.actions.view': 'Detalle factura',
  'billing.timbrado.title': 'Sellos fiscales',
  'billing.timbrado.subtitle': 'Este documento es una representación digital de un CFDI',
  'billing.timbrado.noCertificado': 'Serie del certificado del emisor',
  'billing.timbrado.uuid': 'Folio fiscal',
  'billing.timbrado.noCertificadoSAT': 'No. de serie del certificado del SAT',
  'billing.timbrado.fechaTimbrado': 'Fecha y hora de certificación',
  'billing.timbrado.version': 'Versión',
  'billing.timbrado.selloCFD': 'Sello digital del CFDI',
  'billing.timbrado.selloSAT': 'Sello del SAT',
  'billing.timbrado.cadenaOriginal': 'Cadena original del complemento del certificado digital del SAT',
  'billing.draft': 'Borrador',
  'billing.actions.close': 'Cerrar',
  'billing.input.concept.withTax': 'Con impuesto',
  'billing.input.concept.withTax.error': 'El valor capturado no es válido',
  'billing.input.price.withTax': 'Precios con impuesto',
  'billing.input.price.withCfdiRelacionado': 'Relacionar facturas',
  'billing.contacts.noFound': 'No se encontraron contactos para enviar el correo',
  'billing.contacts.receiver': 'Receptores',
  'billing.contacts.receiver.error': 'Necesita elegir mínimo a un receptor',
  'billing.sendMail.success.title': 'Correos enviados',
  'billing.sendMail.success.description': 'Los correos se enviaron correctamente',
  'billing.input.tipoRelacion': 'Tipo de relación CFDI',
  'billing.input.tipoRelacion.placeholder': 'Seleccione el tipo de relación CFDI',
  'billing.input.cfdiRelacionados': 'UUID relacionados',
  'billing.input.cfdiRelacionados.placeholder': 'Capture los UUID relacionados',
  'billing.input.cfdiRelacionados.verificar': 'Visualizar facturas relacionadas',
  'billing.input.cfdiRelacionados.error': 'Uno o más UUID capturados no tienen el formato correcto',
  'billing.cancel': 'Cancelada',
  'billing.stamp': 'Timbrada',
  'billing.cancelacion.title': 'Acuse de cancelación',
  'billing.cancelacion.subtitle': 'Este documento se encuentra cancelado ante el SAT',
  'billing.stamp.waiting': 'Estamos realizando el proceso de timbrado esto puedo demorar unos segundos',
  'billing.emptyInbox': 'Felicidades, tu inbox está vacío',
  // Mi equipo
  'myTeam.tabs.info': 'Info',
  'myTeam.tabs.api': 'API',
  // Team
  'team.edit.txtButton': 'Editar Equipo',
  'team.add.txtButton': 'Crear Equipo',
  'team.inviteUsers': 'Invitar usuarios',
  'team.invite': 'Invitar',
  'team.user.name': 'Nombre',
  // Configuración
  'config.menu.information': 'Información',
  'config.menu.team': 'Equipo',
  'config.menu.members': 'Miembros',
  'config.menu.api': 'API',
  'config.menu.webhook': 'Webhooks',
  'config.menu.webhook.logs': 'Webhooks Logs',
  'config.menu.billingPlans': 'Facturación y planes',
  'config.menu.security': 'Seguridad',
  // API
  'api.input.add.txtButton': 'Agregar API',
  'api.input.edit.txtButton': 'Editar API',
  'api.input.apiKey': 'API Key',
  'api.input.apiId': 'API ID',
  'api.input.createdAt': 'Creado el',
  'api.input.updatedAt': 'Actualizado el',
  'api.input.revoke': 'Revocar API Key',
  'api.input.show': 'Mostrar',
  'api.input.hide': 'Ocultar',
  // Otros
  'generic.commingSoon': 'Próximamente, sitio en construcción',
  'generic.actions': 'Acciones',
  'generic.field': 'Campo',
  'generic.add': 'Agregar nuevo campo',
  'generic.delete': 'Eliminar campo',
  'generic.message.error': 'Valor incorrecto',
  // Catálogo Productos Sevicios SAT
  'prodserv.title': 'Catálogo de claves de productos y servicios SAT',
  'prodserv.title.keyword': 'Buscar por palabra clave',
  'prodserv.title.category': 'Buscar por categorías',
  'prodserv.type': 'Tipo',
  'prodserv.products': 'Productos',
  'prodserv.services': 'Servicios',
  'prodserv.type.placeholder': 'Selecciona el tipo',
  'prodserv.division': 'División',
  'prodserv.division.placeholder': 'Selecciona la división',
  'prodserv.group': 'Grupo',
  'prodserv.group.placeholder': 'Selecciona el grupo',
  'prodserv.class': 'Clase',
  'prodserv.class.placeholder': 'Selecciona la clase',
  'prodserv.keyword': 'Palabra clave',
  'prodserv.keyword.placeholder': 'Buscar por palabra clave o código de producto',
  'prodserv.keyword.message': 'La palabra a buscar debe tener mínimo 4 caracteres',
  'prodserv.title.results': 'Resultados',
  // Catálogo Unidades SAT
  'unidades.title': 'Catálogo de claves de unidades SAT',
  'unidades.title.code': 'Buscar por código',
  'unidades.subtype': 'Subtipo',
  'unidades.subtype.placeholder': 'Selecciona el subtipo',
  'unidades.keyword.placeholder': 'Buscar por palabra clave',
  'unidades.keyword.message': 'La palabra a buscar debe tener mínimo 4 caracteres',
  'unidades.code': 'Código de unidad',
  'unidades.code.placeholder': 'Buscar por código de unidad',
  'unidades.code.message': 'El código a buscar debe tener mínimo 2 caracteres',
  // Configuración de usuario
  'user.menu.profile': 'Perfil',
  'user.menu.email': 'Cambio de correo',
  'user.menu.company': 'Compañias',
  'user.menu.mycompanies': 'Mis compañias',
  'user.email.newMail': 'Nuevo correo electrónico',
  'user.email.solicitud': 'Generar solicitud para cambiar el correo electrónico',
  'user.email.info': 'Información sobre la solicitud',
  'user.email.description': 'Al generar una nueva solicitud para el cambio de correo electrónico se enviará un email al correo actual y al nuevo; en este último se deberá dar click al enlace para poder realizar el cambio',
  'user.email.success.message': 'Solicitud enviada',
  'user.email.success.description': 'Se envió la solicitud para generar el cambio de contraseña, revisa tu correo para completar el proceso',
  'invitation.email.message': 'Información sobre el correo a registrar',
  'invitation.email.description': 'Tu cuenta será asociada al siguiente correo, más adelante podrás cambiar la cuenta de correo',
  'user.menu.billing': 'Facturación y planes',
  // Companías
  'company.name': 'Nombre',
  'company.name.error': 'Necesita capturar un nombre válido',
  'company.name.placeholder': 'Capture el nombre de la compañia',
  'company.users': 'Total usuarios',
  'company.add.txtButton': 'Crear companía',
  'company.edit.txtButton': 'Editar compañía',
  'company.delete.txtButton': 'Eliminar compañía',
  'company.invitation.txtButton': 'Invitar usuarios',
  'modal.delete.company.warning': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar compañías que no tengan ningún movimiento.',
  'company.invitation.mails.txt': 'Invitaciones',
  'company.invitation.mails.add': 'Agregar nuevo correo',
  'company.invitation.mails.delete': 'Eliminar',
  'company.mail': 'Correo electrónico',
  'company.mail.error': 'Necesita capturar un correo electrónico',
  'company.mail.placeholder': 'Capture el correo electrónico',
  'company.info.message': 'Información sobre estás compañías',
  'company.info.description': 'Las compañías que se muestran a continuación son todas a las que se tiene acceso para poder crear clientes, emisores, facturas, etc.',
  'company.exit': 'Salir de la compañía',
  'company.creator': 'Creador',
  'company.user': 'Usuario',
  'company.exit.title': 'Salir de la compañía',
  'company.exit.okText': 'Salir',
  'company.exit.cancelTxt': 'Cancelar',
  'company.exit.warning': 'La acción que está a punto de realizar no se puede deshacer.',
  'company.select': 'Seleccionar compañía',
  // Webhooks
  'webhook.input.name': 'Nombre',
  'webhook.input.url': 'Endpoint',
  'webhook.input.status': 'Estatus',
  'webhook.add.txtButton': 'Agregar webhook',
  'webhook.edit.txtButton': 'Editar webhook',
  'webhook.input.name.placeholder': 'Capture el nombre del webhook',
  'webhook.input.name.error': 'Es necesario capturar un nombre valido',
  'webhook.input.url.placeholder': 'Capture la url del webhook',
  'webhook.input.url.error': 'Es necesario capturar una url valida',
  'webhook.delete.warning': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar webhooks que no tengan ningún movimiento.',
  'webhook.delete.txtButton': 'Eliminar webhook',
  'webhook.active.txtButton': 'Activar webhook',
  'webhook.inactive.txtButton': 'Deshabilitar webhook',
  'logs.description': 'Status Code',
  'logs.type': 'Content-Type',
  'logs.response': 'Respuesta',
  'logs.status': 'Estatus',
  'logs.usuario': 'Usuario',
  'logs.action': 'Acción',
  'logs.tooltip.statusCode': 'Se excedió el máximo de 10 intentos, hemos deshabilitado el webhook, por favor revisa que esté funcionando y habilítalo manualmente para poder entregar los mensajes',
  'logs.tooltip.status': 'Hubo un problema con tu webhook, haremos varios intentos hasta poder entregar el mensaje',
  'logs.user.externo': 'Externo',
  // Billing error metadata
  'metadata.error.message': 'Error en metadata',
  'metadata.error.description': 'El formato de la metadata es incorrecto, favor de revisar la estructura',
  'billing.merge.txt': 'Unir facturas',
  'billing.merge.uploadExcel': 'Subir Excel',
  'billing.group': 'Grupo',
  // Opciones billing
  'billing.menu.split': 'Separar por conceptos',
  'modal.billing.join.title': '¿Está seguro de unir los conceptos de las facturas?',
  'modal.billing.join.okText': 'Unir',
  'modal.billing.join.description': 'La acción que está a punto de realizar no se puede deshacer, la única alternativa posible es separar la factura por conceptos. Solo se pueden unir facturas del mismo grupo.',
  'modal.billing.split.title': '¿Está seguro de separar los conceptos de las facturas?',
  'modal.billing.split.okText': 'Separar',
  'modal.billing.split.description': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden separar facturas con más de un concepto.',
  'modal.billing.uploadExcel.title': 'Los exceles que subas se convertiran en borradores',
  'modal.billing.uploadExcel.dragTitle': 'Da click o arrastra los archivos al area para subirlos',
  // Pagos
  'billing.invoice.ppd.nopago': 'No. Pago',
  'billing.invoice.ppd.paydate': 'Fecha pago',
  'billing.invoice.ppd.formapago': 'Forma de pago',
  'billing.invoice.ppd.currency': 'Moneda',
  'billing.invoice.ppd.tipocambio': 'Tipo de cambio',
  'billing.invoice.ppd.saldoant': 'Deuda anterior',
  'billing.invoice.ppd.pago': 'Pago',
  'billing.invoice.ppd.deuda': 'Restante',
  'billing.input.pagos.fechaPago': 'Fecha pago',
  'billing.input.pagos.fechaPago.error': 'Es necesario capturar la fecha de pago',
  'billing.input.pagos.monto': 'Monto',
  'billing.input.pagos.monto.error': 'Capture el importe del pago',
  'billing.input.pagos.numOperacion': 'No. operación',
  'billing.input.pagos.rfcEmisorCtaOrd': 'RFC emisor (cta. ord.)',
  'billing.input.pagos.nomBancoOrdExt': 'Banco (ord. ext.)',
  'billing.input.pagos.ctaOrdenante': 'Cta. ordenante',
  'billing.input.pagos.rfcEmisorCtaBen': 'RFC emisor (cta. ben.)',
  'billing.input.pagos.ctaBeneficiario': 'Cta. beneficiario',
  'billing.input.pagos.tipoCadPago': 'Tipo de cadena de pago',
  'billing.input.pagos.certPago': 'Certificado de pago',
  'billing.input.pagos.certPago.error': 'Debe capturar el certificado que corresponde al pago',
  'billing.input.pagos.cadPago': 'Cadena de pago',
  'billing.input.pagos.cadPago.error': 'Debe capturar la cadena original del comprobante que corresponde al pago',
  'billing.input.pagos.selloPago': 'Sello de pago',
  'billing.input.pagos.selloPago.error': 'Debe capturar el sello digital que corresponde al pago',
  'billing.input.pagos.idDocumento': 'UUID',
  'billing.input.pagos.numParcialidad': 'No. pago',
  'billing.input.pagos.impSaldoAnt': 'Saldo anterior',
  'billing.input.pagos.impPagado': 'Importe pagado',
  'billing.input.pagos.impPagado.error': 'Debe capturar un valor válido',
  'billing.input.pagos.impSaldoInsoluto': 'Saldo insoluto',
  'billing.input.pagos.add': 'Agregar complemento de pago',
  'billing.input.pagos.edit': 'Editar complemento de pago',
  'billing.input.pagos.delete': 'Eliminar complemento de pago',
  'billing.input.pagos.name': 'General',
  'billing.input.pagos.name.tooltip': 'Información general para el llenado del complemento de pago',
  'billing.input.pagos.complemento': 'Complemento',
  'billing.input.pagos.complemento.tooltip': 'Información necesaria para el llenado del complemento de pago',
  'billing.invoice.pago': 'Generar complemento de pago',
  'billing.invoice.validation.message': 'Importes incorrectos!',
  'billing.invoice.validation.description': 'Verifica que el importe pagado no sea mayor al saldo anterior',
  'billing.input.pagos.detail': 'Detalle del complemento de pago',
  'billing.generate.pdf': 'Espera unos segundos mientras generamos el documento PDF',
  'billing.generate.zip': 'Espera unos segundos mientras generamos el documento ZIP',
  'billing.pagos.stamp.description': 'Documento sellado',
  'billing.pagos.stamp.pdf': 'Descargar PDF',
  // Bank Accounts
  'menu.category.bank.accounts': 'Cuentas bancarias',
  'bank.accounts.add': 'Administrar cuentas bancarias',
  'bank.form.nocuenta': 'Número de cuenta',
  'bank.form.nocuenta.placeholder': 'Capture el número de cuenta',
  'bank.form.nocuenta.error': 'Debe capturar un número de cuenta válido',
  'bank.form.bank': 'Banco',
  'bank.form.bank.placeholder': 'Seleccione un banco',
  'bank.form.bank.error': 'Debe seleccionar el banco asociado a su cuenta',
  'bank.form.status': 'Estatus',
  'bank.form.status.placeholder': 'Defina el estatus',
  'bank.form.status.error': 'El estatus es obligatorio',
  'bank.add.txt': 'Agregar cuenta bancaria',
  'bank.edit.txt': 'Editar cuenta bancaria',
  'bank.delete.txt': 'Eliminar cuenta bancaria',
  'metadata.title': 'Metadata',
  'metadata.input.frecuency': 'Frecuencia',
  'metadata.input.frecuency.placeholder': 'Selecciona la frecuencia',
  'metadata.input.periodDate': 'Fecha de inicio del periodo',
  'metadata.input.periodDate.placeholder': 'Selecciona la fecha de inicio del periodo',
  'metadata.input.dispersionDate': 'Fecha de dispersión',
  'metadata.input.dispersionDate.placeholder': 'Selecciona la fecha de dispersión',
  'metadata.error': 'Necesita corregir esta estructura para poder aplicar las elecciones',
  'attachments.title': 'Archivos adjuntos',
  'search.receiver.placeholder': 'Buscar por código, RFC, razón social o nombre comercial',
  'search.issuer.placeholder': 'Buscar por RFC, razón social o nombre comercial',
  'modal.delete.bankaccounts': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar cuentas bancarias que no tengan ningún movimiento.',
  'billing.stamp.cancel.waiting': 'Estamos realizando el proceso para cancelar la factura esto puedo demorar unos segundos',
  'message.delete.waiting': 'Estamos realizando el proceso para eliminar esto puede demorar unos segundos',
  'pagos.delete.txt': 'Eliminar complemento de pago',
  'pagos.modal.delete.txt': 'La acción que está a punto de realizar no se puede deshacer. Solo se pueden eliminar complementos de pagos que no hayan sido timbrados.',
  'pagos.cancel.txt': 'Cancelar complemento de pago',
  'invoice.issuer.filter.placeholder': 'Elige o limpia para ver todos',
  'invoice.download.all.xml': 'Descargar todos los XML',
  'invoice.download.all.zip': 'Descargar todos los ZIP (PDF y XML)',
  'invoice.clone.waiting': 'Estamos generando el borrador esto puede demorar unos segundos',
  'message.sendmail.waiting': 'Estamos enviando los correos esto puede demorar unos segundos',
  'error.mail.message': 'Correos no envíados',
  'success.mail.message': 'Correos envíados',
  'xml.mail.message': 'XML',
  'pdf.mail.message': 'PDF',
  'zip.mail.message': 'ZIP',
  'error.random.message': 'Sin errores',
  'filter.startDate.title': 'Fecha inicio',
  'filter.endDate.title': 'Fecha final',
  'filter.date.error.title': 'Fecha incorrecta',
  'filter.startDate.error.title': 'La fecha inicial no puede ser mayor a la fecha final',
  'filter.endDate.error.title': 'La fecha final no puede ser menor a la fecha inicial',
  'zip.message.error': 'No se encontró información para generar el documento ZIP',
  save: 'Guardar',
  cancel: 'Cancelar',
  'profile.name.text': 'Nombre completo',
  'profile.name.placeholder': 'Capture su nombre completo',
  'profile.name.error': 'Debe capturar un nombre',
  'profile.bio.text': 'Biografía',
  'profile.bio.placeholder': 'Cuentanos un poco sobre ti',
  'profile.email.text': 'Correo electrónico',
  'profile.timezone.text': 'Zona horaria',
  'profile.timezone.placeholder': 'Captura tu zona horaria',
  'profile.timezone.error': 'Debes elegir una zona horaria',
  'profile.picture.text': 'Foto de perfil',
  'profile.picture.upload': 'Subir foto de perfil',
  'profile.picture.delete': 'Eliminar foto de perfil',
  'profile.dateOfBirth.text': 'Nacimiento',
  'profile.address.text': 'Dirección',
  'profile.address.placeholder': 'Captura una dirección',
  'profile.address.error': 'Debes capturar una dirección',
  'profile.cp.text': 'Código postal',
  'profile.cp.placeholder': 'Captura tu código postal',
  'profile.cp.error': 'Debes capturar un código postal',
  'profile.country.text': 'País',
  'profile.country.placeholder': 'Selecciona un país',
  'profile.country.error': 'Debes elegir un país',
  'profile.currency.text': 'Divisa de pago',
  'profile.currency.placeholder': 'Elige la divisa de pago',
  'profile.currency.error': 'Debes seleccionar una divisa de pago',
  'profile.password.text': 'Nueva contraseña',
  'profile.password.placeholder': 'Captura la nueva contraseña',
  'profile.newpassword.text': 'Confirma la contraseña',
  'profile.newpassword.placeholder': 'Confirma la nueva contraseña',
  'profile.newpassword.error.message': 'Contraseñas no coinciden',
  'profile.newpassword.error.description': 'No se pudo actualizar la información, las contraseñas no coinciden',
  'menu.admin.text': 'Administrador',
  'menu.admin.product.text': 'Productos',
  'product.name.text': 'Nombre',
  'product.name.placeholder': 'Captura el nombre del producto',
  'product.name.error': 'Debes capturar el nombre del producto',
  'product.price.text': 'Precio',
  'product.price.placeholder': 'Captura el precio del producto',
  'product.price.error': 'Debes capturar el precio del producto',
  'product.quantity.text': 'Cantidad',
  'product.quantity.placeholder': 'Captura la cantidad del producto',
  'product.quantity.error': 'Debes capturar la cantidad del producto',
  'product.description.text': 'Descripción',
  'product.description.placeholder': 'Captura la descripción del producto',
  'product.description.error': 'Debes capturar la descripción del producto',
  'product.features.text': 'Características',
  'product.feature.text': 'Característica',
  'product.feature.placeholder': 'Captura una característica del producto',
  'product.feature.error': 'Debe capturar una característica del producto',
  'product.isfree.text': 'Es gratis',
  'product.status.text': 'Estatus',
  'product.createdAt.text': 'Creación',
  'product.button.add.text': 'Agregar producto',
  'product.button.update.text': 'Editar producto',
  'product.button.feature.text': 'Nueva característica',
  'product.button.feature-delete.text': 'Quitar característica',
  'product.active.text': 'Habilitar producto',
  'product.inactive.text': 'Deshabilitar producto',
  'product.principal.text': 'Marcar producto como principal',
  'product.principal': 'Principal',
  'message.principal.waiting': 'Estamos realizando el proceso para asignar el producto principal esto puede demorar unos segundos',
  'message.status.waiting': 'Estamos realizando el proceso para cambiar el estatus esto puede demorar unos segundos',
  'menu.buy-stamps.text': 'Comprar timbres',
  'menu.buy-stamps.title': 'Elige el paquete que deseas comprar',
  'menu.user.payment-methods': 'Métodos de pago',
  'card.name': 'Nombre que aparece en la tarjeta',
  'card.country': 'País',
  'card.phone': 'Teléfono',
  'card.card': 'Información de la tarjeta',
  'card.card.title': 'Agrega una nueva tarjeta',
  'paymentmethod.last4.text': 'Termina en',
  'paymentmethod.type.text': 'Tipo',
  'paymentmethod.expire.text': 'Expira',
  'paymentmethod.delete.btn.text': 'Eliminar tarjeta',
  'paymentmethod.principal.btn.text': 'Marcar como principal',
  'message.paymentmethod.principal.waiting': 'Estamos realizando el proceso para asignar la tarjeta como principal esto puede demorar unos segundos',
  'modal.delete.paymentmethod.warning': 'La acción que está a punto de realizar no se puede deshacer.',
  'step.one.text': 'Elegir método de pago',
  'step.two.text': '¡Completado!',
  'step.buy.next.text': 'Siguiente',
  'step.buy.previous.text': 'Anterior',
  'step.process.previous.text': 'Procesando el pago',
  'step.buy.done.text': 'Pagar',
  'step.buy.cancel.text': 'Cancelar',
  'step.buy.reload.text': 'Reiniciar',
  'step.buy.folios.text': 'timbres',
  'card.buy.text': 'Tarjeta para pago',
  'card.buy.placeholder': 'Elige la tarjeta para tu pago',
  'card.buy.error': 'Debes capturar la tarjeta con la que realizarás el pago',
  'buy.stamp.details': 'Detalles',
  'buy.stamp.price': 'Precio',
  'buy.stamp.quantity': 'Cantidad',
  'buy.done.success': 'Pago realizado correctamente',
  'paymentmethid.select.title': 'Elige al emisor y el método de pago',
  'invoice.status.filter.placeholder': 'Estatus del pago',
  'history.title.text': 'Historial de timbres',
  'history.availableCredits': 'Total disponibles',
  'history.totalCredits': 'Total históricos',
  'history.usedCredits': 'Total usados',
  'history.expiredCredits': 'Total expirados',
  'menu.issuer.details': 'Detalles del emisor',
  'menu.issuer.banks': 'Cuentas de bancos',
  'menu.issuer.my-buys': 'Mis compras',
  'topBar.buy.stamps': 'Comprar timbres',
  'issuer.buy.text': 'Emisor',
  'issuer.buy.placeholder': 'Elige el emisor que recibirá los timbres',
  'issuer.buy.error': 'Debes elegir al emisor que recibirá los timbres',
  'billing.cancel.pending': 'Cancelación solicitada',
  'issuer.input.firstFolio': 'Folio inicial',
  'issuer.input.firstFolio.placeholder': 'Captura el folio para la primera factura',
  'client.input.automaticFolio': 'F. automático',
  'nps.title': '¿Te está gustando Stampi?',
  'nps.comments': 'Déjanos tus comentarios o sugerencias...',
  'nps.send': 'Enviar comentarios',
  'nps.close': 'Cerrar',
  'issuer.input.lastFolio.placeholder': 'Captura el último folio asignado',
  'issuer.title.update.success': '¡Emisor actualizado!',
  'issuer.description.update.success': 'La actualización se hizo correctamente',
  'invoice.batch.block.tooltip': 'Para desbloquear el folio revise la configuración del emisor',
  'invoice.doc.need': 'Falta alguno de los campos necesarios para adjuntar los documentos',
  'invoice.pagos.ctas': 'Cuentas y bancos',
  'invoice.pagos.ctas.desc': 'Información opcional para rastrear el pago',
  'more.options': 'Más opciones',
  'modal.billing.cancel.pay.okText': 'Cancelar complemento de pago',
  'invoice.download.ppd.report': 'Descargar reporte',
  refresh: 'Refrescar',
  'motive.cancel.title': 'Cancelar factura',
  'motive.cancel.pago.title': 'Cancelar complemento de pago',
  'motive.cancel.description': '¿Cuál es el motivo de la cancelación?',
  'motive.cancel.placeholder': 'Selecciona un motivo de cancelación',
  'motive.cancel.error': 'Es necesario seleccionar un motivo de cancelación',
  'motive.cancel.uuid.relation': 'Para el motivo seleccionado, necesitas asociar el UUID de la nueva factura',
  'motive.cancel.uuid.relation.placeholder': 'Captura el UUID',
  'motive.cancel.uuid.relation.error': 'El motivo requiere asociar un UUID',
  'motive.cancel.when': 'Casos en los que aplica el motivo seleccionado',
  'client.input.Street': 'Calle',
  'client.input.Street.error': 'Debes capturar la calle',
  'client.input.Street.placeholder': 'Captura la calle',
  'client.input.ExteriorNumber': 'Número exterior',
  'client.input.ExteriorNumber.error': 'Debes capturar el número exterior',
  'client.input.ExteriorNumber.placeholder': 'Captura el número ext.',
  'client.input.InteriorNumber': 'Número interior',
  'client.input.InteriorNumber.error': 'Debes capturar el número interior',
  'client.input.InteriorNumber.placeholder': 'Número int.',
  'client.input.Neighborhood': 'Colonia',
  'client.input.Neighborhood.error': 'Debes elegir la colonia',
  'client.input.Neighborhood.placeholder': 'Selecciona la colonia',
  'client.input.Locality': 'Ciudad',
  'client.input.Locality.error': 'Debes elegir una ciudad',
  'client.input.Locality.placeholder': 'Selecciona la ciudad',
  'client.input.Municipality': 'Municipio',
  'client.input.Municipality.error': 'Debes elegir un municipio',
  'client.input.Municipality.placeholder': 'Selecciona el municipio',
  'client.input.State': 'Estado',
  'client.input.State.error': 'Debes elegir un estado',
  'client.input.State.placeholder': 'Selecciona un estado',
  'client.input.Country': 'País',
  'client.input.Country.error': 'Debes elegir un país',
  'client.input.Country.placeholder': 'Selecciona un país',
  'client.input.outsider': 'Cliente extranjero',
  'client.input.taxResidence': 'Clave país',
  'client.input.taxResidence.error': 'Si el cliente es extranjero debe capturar la clave del país',
  'client.input.taxResidence.placeholder': 'Captura la clave',
  'client.input.taxRegistrationNumber': 'Número registro',
  'client.input.taxRegistrationNumber.error': 'Si el cliente es extranjero debe capturar su número de registro',
  'client.input.taxRegistrationNumber.placeholder': 'Captura el número de registro',
  'issuer.input.lastFolioPago': 'Último f. pago',
  'issuer.input.lastFolioPago.placeholder': 'Último f. pago',
  'client.input.automaticFolioPago': 'F. pago aut.',
  'client.input.automaticFolioPago.placeholder': 'F. pago aut.',
  'client.input.taxResidence.tooltip': 'Atributo condicional para expresar la clave del país cuando se trate de extranjero, por ejemplo: USA',
  'client.input.taxRegistrationNumber.tooltip': 'Número de identidad tributaria registrado en el país del cliente extranjero. Obligatorio cuando se trate de extranjero',
  'billing.invoice.aplicar.pago': 'Aplicar pago',
  'billing.payment.add.title': 'Agregar pago',
  'billing.payment.edit.title': 'Editar pago',
  'billing.payment.waiting': 'Estamos aplicando el pago esto puedo demorar unos segundos',
  'billing.input.condiciones-pago': 'Condición de pago',
  'billing.input.condiciones-pago.placeholder': 'Captura la condición de pago',
  'billing.input.exportaciones': 'Exportación',
  'billing.input.exportaciones.placeholder': 'Selecciona el tipo de exportación',
  'billing.input.exportaciones.error': 'Se debe definir el tipo de exportación',
  'billing.input.objeto-imp': 'Objeto de impuesto',
  'billing.input.objeto-imp.placeholder': 'Selecciona el objeto del impuesto',
  'billing.input.objeto-imp.error': 'Se debe definir el objeto del impuesto',
  'invoice.download.ppd.topay': 'Aplicar pago relacionado',
  'invoice.ppd.ppd.topay': 'Aplicar pago relacionado',
  'invoice.ppd.ppd.topay.title': '¿A quién aplicaras el pago?',
  'billing.actions.payto': 'Pago a aplicar',
  stamp: 'Timbrar',
  'menu.category.billing.complements': 'Pagos',
  payment: 'Pago',
  'version.sat': 'Versiones SAT',
  'version.sat.cfdi': 'CFDI',
  'version.sat.pagos': 'Complemento de pagos',
  'version.sat.cancelacion': 'Cancelación',
  'version.sat.error': 'La versión es obligatoría, debe seleccionar una',
  'generic.loading': 'Espera un momento...',
  'magic.login.error': 'Ocurrió un error al iniciar sesión',
  'client.input.blockBatchWebhook': 'Bloquear folio webhook',
  'client.input.blockBatchWebhook.description': 'Esta opción evita que el folio de la factura cambie al guardarse y lo mantiene aunque el folio se encuentre automático',
  'client.input.blockRemoteUpdate': 'Bloquear actualización remota',
  'client.input.blockRemoteUpdate.text': 'Actualización remota bloqueada',
  'client.input.blockRemoteUpdate.description': 'Esta opción evita que se actualice la información del cliente de forma remota (utlizando los webhooks)',
  'client.upload.cif': 'Actualizar información del clientes desde un PDF (CIF)',
  'client.upload.cif.placeholder': 'Adjuntar documento',
  'client.upload.cifvalues.title': 'Actualizar cliente desde CIF',
  'client.upload.cifvalues.addtitle': 'Agregar cliente desde CIF',
  'client.upload.cifvalues.placeholder': 'Elija la información que quiera actualizar',
  'client.input.updateRFC': 'Actualizar el RFC',
  'client.input.rfc.update.warning': 'El RFC del CIF no coincide con el RFC del cliente',
  'client.input.updateBusinessName': 'Actualizar razón social',
  'client.input.updatedTradeName': 'Actualizar nombre comercial',
  'client.input.updateAddress': 'Actualizar dirección',
  'client.input.updatePostalCode': 'Actualizar código postal',
  'client.input.updateRegime': 'Actualizar régimen fiscal',
  'client.add.txtButton.cif': 'Agregar cliente desde CIF',
  'additional.value.title': 'Datos adicionales',
  'additional.value.text': 'Captura un valor',
  'additional.warning.description': 'No olvide guardar la factura para efectuar correctamente la modificación de los datos adicionales',
  'additional.key.period.description': 'Periodo',
  'additional.key.commision.description': 'Comisión',
  'additional.key.costoSocial.description': 'Costo Social',
  'additional.key.periodType.description': 'Tipo de Periodo',
  'additional.key.periodTypeValue.description': 'Valor del Tipo de Periodo',
  'additional.key.period.description.error': 'Se debe seleccionar el tipo de periodo para llenar este campo',
  'additional.key.commision.description.error': 'Este tipo de periodo necesita de una comisión',
  'additional.key.costoSocial.description.error': 'Este tipo de periodo necesita el costo social',
  'additional.key.periodType.description.error': 'Se debe seleccionar un tipo de periodo',
  'additional.key.periodTypeValue.description.error': 'El tipo de periodo seleccionado requiere de un valor',
  'client.input.requiredAdditionalData': 'Datos Adicionales Obligatorios',
  'client.input.requiredAdditionalData.description': 'Al seleccionar esta opción no se puede guardar o timbrar la factura si no tiene los datos adicionales',
  'additional.alert.warning.message': 'Datos adicionales obligatorios',
  'additional.alert.warning.description': 'Para poder guardar o timbrar la factura es necesario llenar la información de los datos adicionales',
  'additional.key.observations.description': 'Observaciones',
  'billing.timbrado.sentBy': 'Envíado por',
  'billing.timbrado.stampBy': 'Timbrado por',
  'billing.timbrado.cancelBy': 'Cancelado por',
  'topBar.status.active': 'Activo',
  'topBar.status.inactive': 'Inactivo',
  'draft.visible.to.me': 'Borrador solo visible para mi',
  'draft.visible.to.me.description': 'Al activar esta opción el borrador solo será visible para ti',
  'modal.billing.join.joinConcepts': 'Unir los conceptos de las facturas (solo se puede unir los conceptos que tengan el mismo código SAT, descripción y valor unitario, al seleccionar esta opción no es posible agregar el periodo al final de la descripción de cada concepto)',
  'modal.billing.join.addPeriod': 'Agregar nombre del periodo al final de la descripción de cada concepto (solo es posible si se encuentra en el metadata, al seleccionar esta opción no es posible unir conceptos)',
  'banxico.error.title': 'Error al obtener el tipo de cambio',
  'billing.global.invoice.title': 'Factura global',
  'billing.input.periodicidad': 'Periodicidad',
  'billing.input.periodicidad.placeholder': 'Seleccione la periodicidad',
  'billing.input.periodicidad.error': 'Debe seleccionar una periodicidad',
  'billing.input.meses': 'Meses',
  'billing.input.meses.placeholder': 'Selecciona un mes',
  'billing.input.meses.error': 'Debe seleccionar un mes',
  'billing.input.anio': 'Año',
  'billing.input.anio.error': 'Debe capturar un año',
  'billing.global.invoice.subtitle': 'Información de la factura global',
  'billing.global.invoice.add': 'Agregar factura global',
  'billing.global.invoice.delete': 'Eliminar factura global',
  'billing.menu.cancel.status': 'Consultar estatus de cancelación',
  'billing.menu.cancel.description': 'Por favor da click en el botón para consultar el estatus actual de la factura. El checkbox solo se deshabilitará si no se logra obtener información acerca del estatus de cancelación de la factura. También se debe tener en cuenta que el SAT puede tardar hasta 72 horas en actualizar el estatus de cancelación de la factura.',
  'billing.menu.cancel.status.clean': 'Remover la cancelación de la factura (no se puede deshacer)',
  'billing.menu.cancel.button': 'Verificar estatus',
  'billing.menu.cancel.revert.button': 'Revertir cancelación',
  'billing.menu.cancel.forced': 'Forzar reversión de cancelación',
  'pdf.language': 'Idioma PDF',
  'pdf.language.error': 'Debe seleccionar un idioma',
  'pdf.language.placeholder': 'Selecciona el idioma',
  'billing.invoice.clone': 'Clonar factura',
  'modal.billing.join.joinService': 'Unir conceptos como un solo servicio (acumular los montos en un solo servicio, solo es posible si comparte el mismo concepto SAT y descripción)',
  'conciliaciones.text': 'Conciliaciones',
  'conciliaciones.abonos.text': 'Abonos',
  'conciliaciones.concepto.text': 'Concepto',
  'conciliaciones.referencia.text': 'Referencia',
  'conciliaciones.referenciaAmpliada.text': 'Referencia ampliada',
  'conciliaciones.fecha.text': 'Fecha del abono',
  'conciliaciones.fecha.text.error': 'Fecha del abono con formato incorrecto',
  'conciliaciones.abono.text': 'Abono',
  'conciliaciones.cargos.text': 'Cargos',
  'conciliaciones.disponible.text': 'Disponible',
  'conciliaciones.cliente.text': 'Cliente',
  'conciliaciones.empresa.text': 'Emisor',
  'conciliaciones.estatus.text': 'Estatus del abono',
  'conciliaciones.afavor.text': 'Con saldo disponible',
  'conciliaciones.sinsaldo.text': 'Sin saldo disponible',
  'conciliaciones.edit.text': 'Editar conciliación',
  'conciliaciones.abono.error': 'El abono no tiene un valor válido',
  'conciliaciones.cargos.add': 'Agregar cargo',
  'conciliaciones.cargos.error': 'El cargo no tiene un valor válido',
  'issuer.input.fiel': 'FIEL',
  'issuer.input.addFiel': 'Renovar FIEL',
  'issuer.input.uploadFiel': 'Cargar FIEL',
  'issuer.input.deleteFiel': 'Eliminar FIEL',
  'issuer.input.csd.info': 'Cuenta con certificados CSD para generar facturas',
  'issuer.input.csd.warning': 'Para poder generar facturas es necesario cargar los documentos CSD',
  'issuer.input.fiel.info': 'Cuenta con la FIEL para descargar facturas',
  'issuer.input.fiel.warning': 'Para poder descargar facturas es necesario cargar los documentos de la FIEL',
  'menu.category.concepts': 'Producto y servicios',
  'menu.category.concepts.myConcepts': 'Mis productos y servicios',
  'billing.actions.preview': 'Vista previa',
  'billing.cancelDraftModal.titleOnCreate': '¿Desea cancelar la creación de la factura?',
  'billing.cancelDraftModal.titleOnEdit': '¿Desea cancelar la edición de la factura?',
  'billing.cancelDraftModal.content': 'Si cancelas se perderá toda la información',
  'billing.cancelDraftModal.okTextOnCreate': 'Cancelar borrador',
  'billing.cancelDraftModal.okTextOnEdit': 'Si, cerrar',
  'billing.cancelDraftModal.cancelText': 'Cerrar',
  // Invoices Importer
  'billingDraft.import.upload.title': 'Importa tus facturas',
  'billingDraft.import.upload.description': 'Si ya cuentas con una base de Facturas aquí te decimos cómo subir los datos a Stampi de manera fácil, rápida y segura.',
  'billingDraft.import.retry.title': 'Problemas en la importación',
  'billingDraft.import.retry.description': 'Ocurrio un problema al guardar las facturas, descarga el archivo para revisar y corregir las filas que tienen errores.',
  'billingDraft.import.success.title': 'Importación correcta',
  'billingDraft.import.success.description': 'La importación se ha realizado satisfactoriamente.',
  'billingDraft.import.stepOne.title': 'Paso 1',
  'billingDraft.import.stepOne.description': 'Descargar el archivo .zip que contiene lo siguientes documentos: Plantilla, guía rápida de como llenar el documento y plantilla de ejemplo.',
  'billingDraft.import.stepTwo.title': 'Paso 2',
  'billingDraft.import.stepTwo.description': 'Sube el archivo con las facturas que deseas agregar, el archivo debe tener la base de la plantilla descargada.',
  'billingDraft.import.btn.downloadTemplate': 'Descargar plantilla',
  'billingDraft.import.btn.downloadTemplate.errors': 'Descargar plantilla con errores',
  'billingDraft.import.btn.downloadTemplate.report': 'Descargar detalle de la importación',
  'billingDraft.import.btn.import': 'Importar',
  'billingDraft.import.btn.importing': 'Importando archivo',
  'billingDraft.import.btn.cancel': 'Cancelar',
  'billingDraft.import.results.invoicesFound': 'Facturas encontradas ',
  'billingDraft.import.results.invoicesSaved': 'Facturas guardadas ',
  'billingDraft.import.results.invoicesWithErrors': 'Facturas con errores ',
  'modal.delete.concept.warning': 'La acción que está a punto de realizar no se puede deshacer. El producto o servició sera eliminado.',
  'modal.delete.template.warning': 'La acción que está a punto de realizar no se puede deshacer. La plantilla "{templateName}" sera eliminada',
  'modal.delete.team.warning': 'La acción que está a punto de realizar no se puede deshacer.',
  // Conceptos
  'concept.title': 'Productos y Servicios',
  'concept.subtitle': 'Define las descripciones del producto o servicio y su precio por unidad',
  'concept.label.descritionConcept': 'Descripción',
  'concept.label.codeProducServ': 'Código Clave o Producto',
  'concept.label.codeProducServ.placeholder': 'Captura el código del concepto',
  'concept.label.codeProducServ.error': 'El código del concepto es incorrecto',
  'concept.label.descProdServ': 'Descripción Clave Producto',
  'concept.label.codeUnit': 'Código Unidad',
  'concept.label.descCodeUnit': 'Descripción Unidad',
  'concept.label.unitValue': 'Precio unitario',
  'concept.label.amountTax': 'Total',
  'concept.label.symbol': 'Simbolo',
  'concept.label.company': 'Compañia',
  'concept.label.barCode': 'Código de barras',
  'concept.label.sku': 'SKU (Stock Keeping Unit)',
  'search.concepts.placeholder': 'Buscar por nombre del producto, descripcion, clave (Producto - SAT) o Unidad',
  'concept.add.txtButton': 'Agregar producto o servicio',
  'concept.add.newTax': 'Puede agregar un nuevo impuesto',
  'concept.edit.txtButton': 'Editar concepto',
  'concept.delete.txtButton': 'Eliminar concepto',
  'concept.delete.title': '¿Esta seguro de eliminar',
  'concept.add.success.title': 'Concepto agregado correctamente',
  'concept.add.success.subtitle': 'Este nuevo concepto ya esta registrado en tus productos y servicios',
  'concept.updated.success.title': 'Concepto actualizado correctamente',
  'concept.updated.success.subtitle': 'Este concepto se ha actualizado y puedes verlo en tus productos y servicios',
  'concept.form.descProdServ': 'Capture para buscar',
  'concept.form.descProdServ.message': 'Tecleé para empezar a buscar',
  'concept.form.descProdServ.error': 'La descripción del producto o servicio es requerida',
  'concept.form.description': 'Capture una descripción',
  'concept.form.description.placeholder': 'Ej: Productos de belleza faciales',
  'concept.form.description.message': 'Texto breve que explican las características y particularidades del producto o servició',
  'concept.form.description.error': 'La descripción es requerida para registrar un producto o servició',
  'concept.form.quantity.message': 'La cantidad no puede ser modificado, su modifcacion es permitida al momento de realizar su factura',
  'concept.form.unit.value.placeholder': 'Ej: 100.00',
  'concept.form.unit.value.message': 'Corresponde al precio por unidad del producto o servició, es necesario para poder aplicar algun descuento',
  'concept.form.unit.value.error': 'El precio unitario debe ser mayor de cero para poder aplicar un tipo de descuento',
  'concept.form.typeDiscount.title': 'Tipo de descuento',
  'concept.form.typeDiscount.message': 'Seleccione el tipo de descuento',
  'concept.form.discount.value.message': 'Descuento de el producto o servicio',
  'concept.form.discount.info': 'Se requiere un precio unitario para poder aplicar un descuento',
  'concept.form.barCode.placeholder': 'Ej: 7502103434890',
  'concept.form.barCode.message': 'Código de barras del producto o servicio',
  'concept.form.sku.placeholder': 'Ej: XYZ-BLN-41',
  'concept.form.sku.message': 'Código de identificación en el inventario, compuesto de forma alfanumerica, es decir por letras y números',
  'concept.form.tooltip.description': 'Puedes buscar un producto/servicio ó puedes escribe una nueva descripción',
  'concept.form.tooltip.descriptionProdSerc': 'Escribe para empezar a buscar la descripción de la clave de producto',
  'concept.form.tooltip.amount': 'Se refiere a la multiplicación de la cantidad por valor unitario',
  'concept.form.tooltip.amountTax': 'Se refiere al importe mas la aplicacion de sus respectivos impuestos',
  'concept.form.tooltip.discount': 'Debe de haber un valor unitario para poder aplicar un descuento, este no debe ser mayor al monto',
  'concept.form.error.message': 'Favor de verificar los campos en el formulario',
  'concept.view.txtButton': 'Ver más',
  'concept.completeInfo': 'Informacion completa del concepto',
  'concept.view.title.create': 'Crear Producto / Servicio',
  'concept.view.title.update': 'Actualizar Producto / Servicio:',
  'concept.search.errorMessage': 'No se encontraron resultados con:',
  'concept.form.title.advanceConfigurate': 'Configuración avanzada',
  'billing.input.paymentMethod.tooltip': 'Elija el método de pago PUE (Pago en una sola exhibición) o PDD (Pago en parcialidades o diferido).',
  'billing.input.invoiceType.tooltip': 'Seleccione entre facturas de tipo: Ingreso, Egreso y Traslado.',
  'billing.input.exchangeRate.tooltip': 'Capture un tipo de cambio con referencia a la moneda mexicana(MXN).',
  'billing.input.concept.unityKey.input.placeholder': 'Escriba o toque el boton para empezar a buscar',
  'billing.input.concept.use.this': 'Utilizar:',
  'billing.input.concept.description.search.placeholder': 'Busca un producto ó captura una descripción',
  'billing.label.add.product': 'Nuevo producto o servicio',
  'billing.label.collapse.general': 'Cálculos generales',
  'billing.label.collapse.tranferred': 'Impuestos trasladados',
  'billing.label.collapse.witheld': 'Impuestos retenidos',
  'billing.label.collapse.vat.tranferred': 'Impuesto trasladado IVA',
  'billing.label.collapse.vat.withheld': 'Impuesto retenido IVA',
  'billing.label.collapse.ieps.tranferred': 'Impuesto trasladado IEPS',
  'billing.label.collapse.isr.withheld': 'Impuesto retenido ISR',
  'billing.input.concept.add': 'Agregar nuevo concepto',
  'billing.input.save.sinTimbrar': 'Guardar sin timbrar',
  'billing.input.save.edit': 'Editar',
  'billing.draft.submit.stamp.concept.title': 'Actualización en productos y servicios',
  'billing.draft.submit.stamp.concept.created': 'Nuevo item fue agregado a tus productos y servicios, da click aqui para ver ',
  'billing.draft.submit.stamp.concepts.createds': 'Nuevos items fueron agregados a tus productos y servicios, da click aqui para verlos ',
  'billing.box.searching': 'Buscando registros',
  'config.team.delete.txtButton': 'Eliminar usuario del equipo',
  'generic.actions.empty': ' ',
  'company.name.description': 'Puedes cambiar el nombre de tu compañía en cualquier momento desde la configuración general.',
  'company.description': 'Una compañía es una agrupación de clientes y facturas en el software. También puedes invitar a otras personas a que colaboren en esta cuenta.',
  'metadata.subtitle': 'Puedes agregar mas información para su integracion con otras plataformas de terceros',
  'message.sendwhats.waiting': 'Estamos enviando tu factura WhatsApp, esto puede demorar unos segundos',
  'message.sendwhats.success': 'WhatsApp enviado correctamente al número {number}',
  'menu.add-stamps.text': 'Agregar más timbres',
  'step.template.create.text': 'Crear plantilla',
  'step.template.update.text': 'Actualizar plantilla',
  'history.purchasedCredits': 'Total Adquiridos',
  'billing.label.condiciones-pago': 'Condición de pago (opcional)',
  'billing.input.condiciones-pago.tooltip': 'Terminos acordados entre comprador y vendedor si aplica, Ejemplo: Número de días en los que se realizará el pago (15 días posteriores a la emisión, 12 meses, etc).',
  'company.user.placeholder': 'Seleccione el nuevo propietario de la compañia',
  'company.input.user.error': 'El usuario es obligatorio',
  'company.input.user.warning': 'No hay mas usuarios para asignar como propietario',
  'profile.defaultCompany.text': 'Compañia predeterminada',
  'profile.btn.wizard': 'Ver tutorial',
  'info.iva': 'Incluye IVA',
  'info.expiration-1': 'Vence en',
  'info.expiration-2': 'Venció hace',
  'info.today': 'Vencido',
  days: 'días',
  hours: 'horas',
  minutes: 'minutes',
  'request.add.text': 'Nueva importación',
  'request.update.text': 'Verificar importación',
  fiel: 'FIEL',
  'rfc.receiver': 'RFC receptor',
  'rfc.receiver.placeholder': 'Captura el RFC del receptor',
  'request.solicitante': 'Solicitante',
  'request.receptor': 'RFC receptor',
  'request.rango': 'Fechas',
  'request.codigo.estado': 'Mensaje de respuesta',
  'request.estado': 'Estatus',
  'request.check.title': '¿Quiéres verificar el estado de la importación?',
  'request.check.content': 'Realizaremos la verificación para comprobar si ya es posible obtener los CFDI\'s, en caso de que no; tendrás que intentarlo más tarde',
  'request.check.ok': 'Verificar',
  'menu.clients.details': 'Detalles del cliente',
  'client.title.update.success': '¡Cliente actualizado!',
  'verify.request.subtitle': 'Es necesario cargar los archivos de la FIEL y contraseña para poder iniciar o verificar la importación',
  'total.invoices': 'Total',
  'total.invoices.description': 'Total de facturas descargadas',
  'request.solicitante.error': 'Debes seleccionar al solicitante',
  'request.solicitante.placeholder': 'Selecciona al solicitante',
  'request.filter.emisor': 'Traer las facturas emitidas por el solicitante',
  'rfc.issuer': 'RFC emisor',
  'rfc.issuer.placeholder': 'Captura el RFC del emisor',
  'request.filter.receptor': 'Traer las facturas recibidas por el solicitante',
  'request.errores': 'Ver errores',
  'request.errores.title': 'La solicitud presento uno o más errores',
  'request.history': 'Historial de actualizaciones',
  'request.history.title': 'Aquí se muestra el historial de los usuarios que han realizado las solicitudes',
  'request.rfcs.error': 'El solicitante debe estar mínimo como emisor o receptor, pero no ambos',
  'request.invoice.search': 'Buscar por RFC o razón social',
  'request.receiver.search': 'Buscar por RFC',
  'dont.close.window': 'Por favor no cierres la ventana hasta que el proceso concluya',
  'generate.zip': 'Generando el archivo zip',
  // Concept
  'concept.import.upload.title': 'Importa tus productos o servicios',
  'concept.import.upload.description': 'Si ya cuentas con una base de Productos/Servicios aquí te decimos cómo subir los datos a Stampi de manera fácil, rápida y segura.',
  'concept.import.retry.title': 'Importación correcta pero hubo {totalInvalidRows} {totalInvalidRows, plural, one {error} other {errores}}',
  'concept.import.retry.description': 'Ocurrio un problema al agregar algunos Productos/Servicios, descarga el archivo para revisar el detalle de los elementos que no se pudieron agregar satisfactoriamente.',
  'concept.import.success.title': 'Importación correcta',
  'concept.import.success.description': 'La importación se ha realizado satisfactoriamente.',
  'concept.import.stepOne.title': 'Paso 1',
  'concept.import.stepOne.description': 'Descargar el archivo .zip que contiene lo siguientes documentos: Plantilla, guía rápida de como llenar el documento y plantilla de ejemplo.',
  'concept.import.stepTwo.title': 'Paso 2',
  'concept.import.stepTwo.description': 'Sube el archivo con los Productos/Servicios que deseas agregar, el archivo debe tener la base de la plantilla descargada.',
  'concept.import.btn.downloadTemplate': 'Descargar plantilla',
  'concept.import.btn.downloadTemplate.errors': 'Descargar plantilla con errores',
  'concept.import.btn.import': 'Importar',
  'concept.import.btn.importing': 'Importando archivo',
  'concept.import.btn.cancel': 'Cancelar',
  'concept.import.results.totalValidRows': 'Filas válidas ',
  'concept.import.results.totalInvalidRows': 'Filas erróneas ',
  'concept.import.results.totalCreated': 'Registros creados ',
  'concept.import.results.totalUpdated': 'Registros actualizados ',
  // Invoice Preview
  'preview.totals.amount': 'Importe: ',
  'preview.totals.discount': 'Descuentos: ',
  'preview.totals.ivaTransferred': 'IVA T: ',
  'preview.totals.ivaWitheld': 'IVA R: ',
  'preview.totals.ieps': 'IEPS: ',
  'preview.totals.isr': 'ISR: ',
  'preview.totals.subtotal': 'Subtotal: ',
  'preview.totals.total': 'Total: ',
  'preview.taxes.exempt': 'Exento de impuestos',
  // wizard
  'wizard.modal.initial.title': '¡Bienvenido!',
  'wizard.modal.initial.content': 'Está guía rápida te va a mostrar cómo puedes emitir una factura a tus clientes de inicio a fin y simplificar tu proceso de facturación electrónica con Stampi.',
  'wizard.modal.clients.title': 'Clientes',
  'wizard.modal.clients.content': 'Dentro de esta sección podrás gestionar todos los clientes a los que les quieras facturar de una forma fácil y organizada.',
  'wizard.modal.issuers.title': 'Emisores',
  'wizard.modal.issuers.content': 'Dentro de esta sección podrás gestionar todos los emisores con los que quieras generar tus facturas de una forma fácil y organizada. Recuerda que con Stampi la facturación no está limitada a un solo emisor.',
  'wizard.modal.preinvoice.title': 'Prefacturas',
  'wizard.modal.preinvoice.content': 'Dentro de esta sección podrás gestionar todas las prefacturas y ver el historial de los correos enviados.',
  'wizard.modal.omit.title': '¿Está seguro que desea omitir el tutorial?',
  'wizard.modal.omit.content': 'Después lo puede activar en la sección Perfil de usuario > Preferencias',
  'wizard.btn.start': 'Comenzar',
  'wizard.btn.continue': 'Continuar',
  'wizard.btn.omit': 'Omitir',
  'wizard.btn.omitConfirm': 'Sí, omitir',
  'wizard.btn.goToSection': 'Ir a la sección',
  'wizard.btn.finish': 'Finalizar',
  'wizard.btn.newInvoice.title': 'Crea tu nueva factura',
  'wizard.btn.newInvoice.content': 'Para empezar debemos presionar el botón de “Crear Factura” donde crearemos una nueva.',
  'wizard.btn.newInvoice.starContent': 'Haz clic en el botón de arriba: "Crear factura" para continuar.',
  'wizard.btn.newClient.title': 'Nuevo cliente',
  'wizard.btn.newClient.content': 'Para crear uno debes hacerlo presionando el botón de "Agregar cliente".',
  'wizard.btn.newIssuer.title': 'Crear un nuevo emisor',
  'wizard.btn.newIssuer.content': 'Para crear un nuevo emisor de formal manual debes hacerlo presionando el botón de "Agregar emisor".',
  'wizard.invoice.listClient.title': 'Clientes',
  'wizard.invoice.listClient.content': 'Aquí se encuentra el listado de clientes quienes son los receptores de las facturas que emitamos.',
  'wizard.invoice.createClient.title': 'Crear nuevo cliente',
  'wizard.invoice.createClient.content': 'Necesitas crear un nuevo cliente para poder seleccionarlo como una opción dentro de este listado.',
  'wizard.invoice.createdClient.title': 'Crear nuevo cliente',
  'wizard.invoice.createdClient.content': 'Al seleccionar tu nuevo cliente/receptor se llenarán en automático los campos de: Uso del CFDI, Régimen fiscal y Código postal.',
  'wizard.invoice.listIssuer.title': 'Emisores',
  'wizard.invoice.listIssuer.content': 'Aquí se encuentra el listado de emisores de las facturas que generes.',
  'wizard.invoice.createIssuer.title': 'Crear nuevo emisor',
  'wizard.invoice.createIssuer.content': 'Ahora, necesitas crear un nuevo emisor para poder seleccionarlo como una opción dentro de este listado.',
  'wizard.invoice.createdIssuer.title': 'Emisores',
  'wizard.invoice.createdIssuer.content': 'Ahora puedes seleccionar tu nuevo emisor en la lista y se llenarán en automático los campos de: Régimen fiscal, Código postal, entre otros campos opcionales en el registro del emisor.',
  'wizard.invoice.generalInfo.title': 'Información general',
  'wizard.invoice.generalInfo.content': 'Para continuar con la creación de la factura es necesario el llenado de algunos datos generales propios de la factura que se muestran a continuación y donde los campos con (*) son obligatorios.',
  'wizard.invoice.concepts.title': 'Conceptos',
  'wizard.invoice.concepts.content': 'Para continuar con la creación de la factura es necesario el llenado de los “Conceptos”, es decir, los productos o servicios por los cuales se está haciendo la factura y pueden agregarse múltiples, de ser necesario.',
  'wizard.invoice.sendEmail.title': 'Envío por correo',
  'wizard.invoice.sendEmail.content': 'Podrás enviar la prefactura por correo electrónico a tu cliente quien recibirá un enlace para validar la información de la prefactura, y en su caso, podrá agregar o modificar dichos datos previo al timbrado de la factura.',
  'wizard.invoice.save.title': 'Vista previa',
  'wizard.invoice.save.content': 'Una vez que ya se hayan completado todos los campos necesarios para crear la factura podrás elegir entre “Cancelar”, para cerrar la ventana y no guardar ninguno de los cambios realizados en la creación de la factura; o “Vista Previa” para continuar con la previsualización de la factura previo a su emisión y timbrado ante el SAT.',
  'wizard.clients.formHelp': 'Para agregar un nuevo cliente necesitas completar los siguientes datos del formulario donde los campos con (*) son obligatorios. Por ahora solo es un recorrido en la interfaz, posteriormente podrás crear un nuevo registro.',
  'wizard.issuers.formHelp': 'Para agregar un nuevo emisor necesitas completar los siguientes datos del formulario donde los campos con (*) son obligatorios. Por ahora solo es un recorrido en la interfaz, posteriormente podrás crear un nuevo registro.',
  // Preinvoice
  'preinvoice.loading': 'Cargando Factura',
  'preinvoice.stamped.title': 'Factura timbrada',
  'preinvoice.stamped.subtitle': 'El comprobande de la factura ha sido enviado a su correo electrónico.',
  'preinvoice.rejected.title': 'Solicitud enviada',
  'preinvoice.stampForm.checkbox.placeholder': 'Acepto que todos los datos que presenta esta factura son correctos',
  'preinvoice.stampForm.checkbox.error': 'Por favor marque la casilla',
  'preinvoice.stampForm.cancel': 'Solicitar corrección',
  'preinvoice.stampForm.edit': 'Modificar mis datos',
  'preinvoice.stampForm.submit': 'Timbrar mi factura',
  'preinvoice.stampForm.sending': 'Timbrando factura',
  'preinvoice.rejectForm.title': 'Solicitar corrección',
  'preinvoice.rejectForm.textarea.label': 'Motivo',
  'preinvoice.rejectForm.textarea.placeholder': 'Motivo por el cual desea solicitar la correción',
  'preinvoice.rejectForm.textarea.error': 'Por favor ingrese el motivo por el cual desea solicitar la correción',
  'preinvoice.rejectForm.submit': 'Enviar solicitud',
  'preinvoice.rejectForm.sending': 'Enviando solicitud',
  'preinvoice.rejectForm.cancel': 'Cancelar',
  'preinvoice.receiverForm.title.onCreate': 'Agregar mis datos',
  'preinvoice.receiverForm.title.onEdit': 'Modificar mis datos',
  'preinvoice.receiverForm.submit': 'Guardar mis datos',
  'preinvoice.receiverForm.sending': 'Guardando mis datos',
  'preinvoice.downloadFilesForm.rfc.label': 'RFC',
  'preinvoice.downloadFilesForm.rfc.subtitle': 'Si deseas descargar los comprobantes de la factura llena el siguiente formulario',
  'preinvoice.downloadFilesForm.rfc.placeholder': 'Ingresa tu RFC',
  'preinvoice.downloadFilesForm.rfc.error': 'Por favor ingresa tu RFC',
  'preinvoice.downloadFilesForm.submit': 'Verificar RFC',
  'preinvoice.downloadFilesForm.sending': 'Verificando RFC',
  'preinvoice.download.pdf': 'Descargar PDF',
  'preinvoice.download.xml': 'Descargar XML',
  'preinvoice.xml.waiting': 'Estamos generando el documento XML esto puedo demorar unos segundos',
  // Template
  'template.action.create': 'Nueva plantilla creada',
  'template.action.delete': 'Plantilla eliminada',
  'template.action.update': 'Plantilla actualizada',
  'template.add.txtButton': 'Nueva plantilla',
  'template.btn.data.empty': 'Creemos tu primer plantilla',
  'template.concepts.create.first': 'Creemos tu primer concepto',
  'template.concepts.empty': 'Sin conceptos (productos o servicios) asociados a esta plantilla',
  'template.concepts.modified': 'Creemos que usted ha modificado la información del concepto, si esto es correcto por favor de clic en aceptar para actualizar la información del concepto.',
  'template.concepts.search': 'Busquemos un concepto (producto o servicio) ',
  'template.create': 'Creando nueva plantilla  "{templateName}"',
  'template.data.empty': 'Sin plantillas registradas',
  'template.data.name': 'Nombre plantilla',
  'template.data.search': 'Busca por nombre, emisor, receptor, concepto ó total',
  'template.data.type': 'Se refiere al tipo de plantilla. Rapida: plantilla que puede utilizarse en "facturas rapidas" y se compone de un emisor + IVA Trasladado, Recurrente: plantilla que se utiliza en "Crear factura" de forma mas general y detallada',
  'template.delete': 'Borrando plantilla  "{templateName}"',
  'template.import.txtButton': 'Importar',
  'template.input.name.error': 'El nombre de la plantilla es obligatorio',
  'template.input.name.placeholder': 'Establezca el nombre de la plantilla',
  'template.input.name.text': 'Nombre de la plantilla',
  'template.select.issuer': 'Seleccione emisor',
  'template.select.label': 'Plantillas',
  'template.select.placeholder': 'Seleccione una plantilla',
  'template.select.receiver': 'Seleccione receptor',
  'template.subtitle.concepts': 'Podemos buscar conceptos (productos y servicios) que tengas registrados y adjuntarlos a esta plantilla',
  'template.subtitle.config': 'Establezca la configuración para poder reutilizarla en facturas posteriores',
  'template.subtitle.persons': 'Seleccione a las personas {icon} que desee establecer en esta platilla',
  'template.subtitle': 'Establezca un nombre a su nueva plantilla',
  'template.title.config': 'Configuración de la factura',
  'template.title.persons': 'Personas',
  'template.update': 'Actualizando plantilla  "{templateName}"',
  // Fast Invoice
  'fast.invoice.step.one.title': 'Selecciona plantilla',
  'fast.invoice.step.one.modal.subtitle': 'Selecciona una plantilla rápida para esta prefactura',
  'fast.invoice.step.one.input.search.placeholder': 'Pulsa una tecla para empezar a buscar',
  'fast.invoice.step.one.input.search.searching': 'Buscando...',
  'fast.invoice.step.two.title': 'Ingresa monto total',
  'fast.invoice.step.two.modal.title': 'Monto total a facturar',
  'fast.invoice.step.two.modal.subtitle': 'Establece el monto total de esta prefactura',
  'fast.invoice.step.three.modal.option.1.title': 'Número celular',
  'fast.invoice.step.three.modal.option.1.subtitle': 'Establece el número celular al que haremos llegar la prefactura',
  'fast.invoice.step.three.modal.option.2.title': 'Correo electrónico',
  'fast.invoice.step.three.modal.option.2.subtitle': 'Establece el correo al que haremos llegar la prefactura',
  'fast.invoice.step.three.title': 'Elige canal de envío',
  'fast.invoice.step.three.modal.channel': 'Elige un canal de envío',
  'fast.invoice.step.four.title': 'Verifica y envía',
  'fast.invoice.step.four.modal.title': 'Envió de factura',
  'fast.invoice.step.four.modal.subtitle': 'Antes de enviar por favor verifica si todos los datos de tu factura son correctos al igual que el número celular.',
  'fast.invoice.send': 'Enviar prefactura',
  'fast.invoice.new': 'Nueva prefactura rápida',
  'fast.invoice.sent': 'Prefactura Enviada',
  'fast.invoice.description.general.information': 'Información general',
  // importador clientes
  'client.import.upload.title': 'Importa tus clientes',
  'client.import.upload.description': 'Si ya cuentas con una base de clientes, aquí te decimos cómo subir los datos a Stampi de manera fácil, rápida y segura.',
  'client.import.retry.title': 'Importación correcta pero hubo {totalInvalidRows} {totalInvalidRows, plural, one {error} other {errores}}',
  'client.import.retry.description': 'Ocurrio un problema al agregar algunos Clientes, descarga el archivo para revisar el detalle de las filas que no se pudieron agregar satisfactoriamente.',
  'client.import.success.title': 'Importación correcta',
  'client.import.success.description': 'La importación se ha realizado satisfactoriamente.',
  'client.import.stepOne.title': 'Paso 1',
  'client.import.stepOne.description': 'Descargar el archivo .zip que contiene lo siguientes documentos: Plantilla, guía rápida de como llenar el documento y plantilla de ejemplo.',
  'client.import.stepTwo.title': 'Paso 2',
  'client.import.stepTwo.description': 'Sube el archivo con los Clientes que deseas agregar, el archivo debe tener la base de la plantilla descargada.',
  'client.import.btn.downloadTemplate': 'Descargar plantilla',
  'client.import.btn.downloadTemplate.errors': 'Descargar plantilla con errores',
  'client.import.btn.import': 'Importar',
  'client.import.btn.importing': 'Importando archivo',
  'client.import.btn.cancel': 'Cancelar',
  'client.import.results.totalValidRows': 'Filas válidas ',
  'client.import.results.totalInvalidRows': 'Filas erróneas ',
  'client.import.results.totalCreated': 'Registros creados ',
  'client.import.results.totalUpdated': 'Registros actualizados ',
  'preinvoice.title': 'Prefacturas',
  'preinvoice.tag': 'Prefactura',
  'emailTracker.sender': 'Envió',
  'emailTracker.receiver': 'Recibió',
  'emailTracker.sendate': 'Fecha de envío',
  'emailTracker.open': 'Visto',
  'emailTracker.firstopen': 'Primera vez abierto',
  'emailTracker.lastopen': 'Última vez abierto',
  'emailTracker.totalviews': 'Veces visto',
  'emailTracker.rejected': 'Rechazada',
  'invoice.errors.title.concepts': '¡Revisa los conceptos!',
  'invoice.errors.title.client': '¡Falta información!',
  'invoice.errors.description.client': 'Solo es posible enviar la prefactura sin los datos del cliente, revisa la demás información',
  // Documentacion
  'documentatio.title': 'documentación',
  'documentation.api.txt': 'Conoce más acerca de lo que puedes hacer con la API de Stampi en nuestra',
  'documentation.webhook.txt': 'Conoce más acerca de lo que puedes hacer con los Webhooks de Stampi en nuestra',
  // Dashboard
  'dashboard.wait': 'Cargando Dashboard',
  'dashboard.noIssuers': 'Aun no tienes registrado ningún',
  'dashboard.create.issuer.btn': 'Crear un emisor',
  'dashboard.datepicker.placeholder': 'Selecciona el ejercicio',
  'dashboard.charts.invoice.subtitle': 'Gráficas de facturas timbradas y canceladas ',
  'dashboard.stamps.subtitle': 'Timbres utilizados según ejercicio seleccionado',
  'dashboard.charts.without.stamps': 'Sin facturas registradas (STAMPI)',
  'dashboard.charts.without.canceled': 'Sin facturas canceladas',
  'dashboard.charts.without.total': 'Sin registro de montos (STAMPI)',
  'dashboard.charts.without.total.sat': 'Sin registro de montos (SAT)',
  'dashboard.charts.title.amount': 'Montos',
  'dashboard.charts.amount.subtitle': 'Gráficas de dinero facturado, ingresos y egresos',
  'dashboard.charts.stampi': 'Montos Facturados (STAMPI)',
  'dashboard.charts.money': 'Montos Facturados (SAT)',
  'dashboard.import.success': 'Importación realizada',
  'dashboard.import.receiver': 'Recibidas',
  'dashboard.import.issued': 'Emitidas',
  'dashboard.import.lastSync': 'Fecha de realización de importación',
  'dashboard.import.downloaded.invoices': 'Facturas descargadas',
  'dashboard.import.verifying.import': 'Verificando importación',
  'dashboard.import.started': 'Importación iniciada',
  'dashboard.import': 'Importación',
  'dashboard.revenues': 'Ingresos',
  'dashboard.expenses': 'Egresos',
  'dashboard.utility': 'Utilidad',
  'dashboard.import.subtitle': 'Importación automatica de facturas registradas ante SAT',
  'sat.delete.fiel': 'Eliminar la FIEL después de descargar las facturas «solo se eliminará de la solicitud»',
  // Promociones
  'search.promotion.placeholder': 'Buscar por nombre o descripción',
  'promotion.delete.txtButton': 'Eliminar promoción',
  'modal.delete.promotion.warning': 'La acción que está a punto de realizar no se puede deshacer.',
  'promotion.add.txtButton': 'Agregar promoción',
  'promotion.edit.txtButton': 'Editar promoción',
  'promotion.input.name': 'Nombre de la promoción',
  'promotion.input.name.error': 'Por favor captura el nombre',
  'promotion.input.name.placeholder': 'Captura el nombre',
  'promotion.input.description': 'Cuerpo del mensaje',
  'promotion.input.description.error': 'Por favor captura la descripción',
  'promotion.input.description.max': 'La longitud maxima es de 100 caracteres',
  'promotion.input.description.placeholder': 'Captura la descripción (máximo 100 caracteres)',
  'promotion.input.image': 'Imagen',
  'promotion.input.image.placeholder': 'Cargar imagen',
  'promotion.input.image.drawerTitle': 'Haga clic o arrastre el archivo a esta área para cargarlo',
  'promotion.input.image.drawerDesc': 'Solo se permiten imágenes con los siguientes formatos: jpg, jpeg y png',
  'promotion.input.url': 'Enlace',
  'promotion.input.url.error': 'Por favor introduce una URL válida',
  'promotion.input.url.placeholder': 'Captura el enlace',
  'promotion.input.clics': 'Total de clics',
  'place.import': 'Importador',
  'pue.update': 'Actualizar PUE\'s',
  'client.contacts.upsert': 'Actualizar contactos',
  'concepts.descriptions': 'Conceptos',
  'concepts.descriptions.add': 'Agregar concepto',
  'concepts.descriptions.update': 'Actualizar concepto',
  'billing.input.concept.unityKey.desc.error': 'La descripción del concepto es requerida',
  'status.yes': 'Si',
  'status.no': 'No',
  'cancel.charge': '¿Estás seguro de cancelar el cargo?',
  'concepts.issuer.error': 'Es posible que el emisor no tenga productos o servicios registrados',
  'concepts.issuer.delete.content': '¿Estás seguro de eliminar el concepto?',
  'issuer.isLaborUnion': '¿Es un sindicato de trabajadores?',
  'issuer.isLaborUnion.placeholder': 'Selecciona si es un sindicato de trabajadores',
  'issuer.idPlan': 'ID Plan',
  'issuer.idPlan.placeholder': 'Captura el ID plan',
  'input.yes': 'Si',
  'input.no': 'No',
  'modal.billing.fromSAT.description': 'La acción que está a punto de realizar generará un nuevo borrador con la información de la factura descargada por el SAT.',
  'verify.certs.title': '¿Quieres verificar las fechas de caducidad?',
  'verify.certs.content': 'Realizaremos la verificación para comprobar si las fechas de caducidad de tus certificados son correctas.',
  'verify.certs.ok': 'Verificar',
  csdExpirationDate: 'Fecha expiración CSD',
  fielExpirationDate: 'Fecha expiración FIEL',
};

export default {
  locale: 'es-MX',
  localeAntd,
  messages,
};
